import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import msalInstance from "./Auth/AuthProvider";
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, } from '@azure/msal-browser';
import { loginRequest, msalConfig } from "../src/Auth/authConfig";


const pca = new PublicClientApplication(msalConfig);



ReactDOM.render(
    <MsalProvider instance={pca}>
        <App />
    </MsalProvider>
    , document.querySelector("#root"));
