import React, { Component, Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { StreetInput } from "./StreetInput";

class ContractCustomer extends Component {
  render() {
    const states = [
      "AL",
      "AK",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "DC",
      "FL",
      "GA",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "OH",
      "OK",
      "OR",
      "PA",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
    ];

    const {
      address,
      address2,
      city,
      email,
      firstName,
      handleInputChange,
      isAddressValid,
      isAddressValidated,
      isCityValid,
      isCityValidated,
      isEmailValid,
      isEmailValidated,
      isFirstNameValid,
      isFirstNameValidated,
      isLastNameValid,
      isLastNameValidated,
      isPostalCodeValid,
      isPostalCodeValidated,
      isPrimaryPhoneValid,
      isPrimaryPhoneValidated,
      isSecondaryPhoneValid,
      isSecondaryPhoneValidated,
      isStateValid,
      isStateValidated,
      lastName,
      postalCode,
      primaryPhone,
      secondaryPhone,
      state,
    } = this.props;

    return (
      <Fragment>
        <h2>Customer</h2>
        <Form.Group as={Row} controlId="firstName">
          <Form.Label column xs={6}>
            First Name:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete="off"
              className={
                !isFirstNameValidated
                  ? null
                  : isFirstNameValid
                    ? "is-valid"
                    : "is-invalid"
              }
              onChange={(event) => {
                handleInputChange(event);
              }}
              placeholder="First Name"
              size="sm"
              type="text"
              value={firstName}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a first name.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="lastName">
          <Form.Label column xs={6}>
            Last Name:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete="off"
              className={
                !isLastNameValidated
                  ? null
                  : isLastNameValid
                    ? "is-valid"
                    : "is-invalid"
              }
              onChange={(event) => {
                handleInputChange(event);
              }}
              placeholder="Last Name"
              size="sm"
              type="text"
              value={lastName}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a last name.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="address">
          <Form.Label column xs={6}>
            Address:
          </Form.Label>
          <Col xs={6}>
            <StreetInput
              address={address}
              handleInputChange={handleInputChange}
              isAddressValid={isAddressValid}
              isAddressValidated={isAddressValidated}
            />
            {/* <Form.Control.Feedback type="invalid">
              Please provide an address.
            </Form.Control.Feedback> */}
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="address2">
          <Form.Label column xs={6}>
            Address 2:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete="off"
              onChange={(event) => {
                handleInputChange(event);
              }}
              placeholder="Additional Address"
              size="sm"
              type="text"
              value={address2}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="city">
          <Form.Label column xs={6}>
            City:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete="off"
              className={
                !isCityValidated
                  ? null
                  : isCityValid
                    ? "is-valid"
                    : "is-invalid"
              }
              onChange={(event) => {
                handleInputChange(event);
              }}
              placeholder="City"
              size="sm"
              type="text"
              value={city}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a city.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="state">
          <Form.Label column xs={6}>
            State:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="select"
              autoComplete="off"
              className={
                !isStateValidated
                  ? null
                  : isStateValid
                    ? "is-valid"
                    : "is-invalid"
              }
              onChange={(event) => {
                handleInputChange(event);
              }}
              size="sm"
              value={state}
            >
              <option key="" value="">
                Select state...
              </option>
              {states.map((state, i) => (
                <option key={i} value={state}>
                  {state}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a state.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="postalCode">
          <Form.Label column xs={6}>
            Postal Code:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete="off"
              className={
                !isPostalCodeValidated
                  ? null
                  : isPostalCodeValid
                    ? "is-valid"
                    : "is-invalid"
              }
              maxLength={5}
              onChange={(event) => {
                handleInputChange(event);
              }}
              placeholder="Postal Code"
              size="sm"
              type="text"
              value={postalCode}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid postal code.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="email">
          <Form.Label column xs={6}>
            Email:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete="off"
              className={
                !isEmailValidated
                  ? null
                  : isEmailValid
                    ? "is-valid"
                    : "is-invalid"
              }
              onChange={(event) => {
                handleInputChange(event);
              }}
              placeholder="Email"
              size="sm"
              type="email"
              value={email}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="primaryPhone">
          <Form.Label column xs={6}>
            Primary Phone:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete="off"
              className={
                !isPrimaryPhoneValidated
                  ? null
                  : isPrimaryPhoneValid
                    ? "is-valid"
                    : "is-invalid"
              }
              maxLength={14}
              onChange={(event) => {
                handleInputChange(event);
              }}
              placeholder="(123) 456-7890"
              size="sm"
              type="text"
              value={primaryPhone}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a primary phone number.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="secondaryPhone">
          <Form.Label column xs={6}>
            Secondary Phone:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete="off"
              className={
                !isSecondaryPhoneValidated
                  ? null
                  : isSecondaryPhoneValid
                    ? "is-valid"
                    : "is-invalid"
              }
              maxLength={14}
              onChange={(event) => {
                handleInputChange(event);
              }}
              placeholder="(123) 456-7890"
              size="sm"
              type="text"
              value={secondaryPhone}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid secondary phone.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </Fragment>
    );
  }
}

export default ContractCustomer;
