import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Grid } from '@mui/material';
import moment from 'moment';
import Icon from "../../img/delete 1FInalConfirmation.svg"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function ConfirmationRescheduleDialog({ open, handleClose, data, servicer, consumer, finalBookingTimeSlot }) {

    return (
        <React.Fragment>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}

                PaperProps={{
                    style: {
                        borderRadius: '20px',
                        width: "29rem",
                        height: "30rem"
                    },
                }}
            >
                <DialogTitle style={{ textAlign: "center" }} sx={{ m: 2, p: 2, mb: 0 }} id="customized-dialog-title">
                    <img src={Icon} alt='user-pic' height="45px" width="45px" style={{ background: "rgba(235, 248, 255, 1)", padding: "10px", borderRadius: "50%" }} />
                    {/* <EventAvailableIcon sx={{ fonSize: "100px", height: "45px", width: "45px" }} /> */}
                </DialogTitle>

                <DialogContent sx={{ textAlign: "center" }} >
                    <Grid >
                        <span style={{ fontWeight: "600", fontSize: "15px" }}>
                            {servicer?.account?.businessName} has been rescheduled to
                            Service Order #{consumer?.serviceOrder?.providerType === "A&E" ? data?.callNumber : data?.serviceOrderNumber}
                            &nbsp; for {moment(finalBookingTimeSlot?.appointmentDate).format('MMMM DD, YYYY')}, {finalBookingTimeSlot?.appointmentTimeSlot}
                        </span>
                    </Grid>

                    <Grid style={{ marginTop: "1rem" }}>
                        <span style={{ fontSize: "200" }}>
                            Reschedule notification has been sent to Service Provider <span> </span>
                            <span style={{ fontWeight: "600" }}>{servicer?.account?.businessName}</span> at <span> </span>
                            {servicer?.dispatchMethod?.primaryPhone} and<span> </span>
                            {servicer?.dispatchMethod?.primaryEmail}
                            .
                        </span>
                    </Grid>

                    <Grid style={{ marginTop: "1rem" }}>
                        <span style={{ fontSize: "200" }}>
                            Reschedule notification has been sent to Consumer <span> </span>
                            {consumer?.consumer?.customerName}  at <span> </span>
                            {consumer?.consumer?.phone} and <span> </span>
                            {consumer?.consumer?.email}
                            .
                        </span>
                    </Grid>

                    <br />
                    <Grid item xs={12} style={{ justifyContent: "center", marginTop: "1.8rem" }}>

                        <Button variant="contained" onClick={handleClose}>
                            Okay
                        </Button>

                    </Grid>

                </DialogContent>

            </BootstrapDialog>
        </React.Fragment>
    );
}
