import React, { Fragment, Component } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";

class ContractList extends Component {
  state = {
    contractDisplayList: [],
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ contractDisplayList: nextProps.contractList });
  }

  getVariant = (contractNumber) => {
    const { selectedContract } = this.props;
    if (
      selectedContract?.contractNumber.toUpperCase() ===
      contractNumber.toUpperCase() &&
      selectedContract != null
    ) {
      return "table-active contractListRow";
    } else {
      return contractNumber === null
        ? "table-light contractListRow"
        : "contractListRow";
    }
  };

  getNewContractVariant = (newContract) => {
    const { selectedContract } = this.props;
    if (
      selectedContract?.contractNumber === newContract?.contractNumber &&
      selectedContract != null
    ) {
      return "table-active contractListRow";
    } else {
      return newContract === null
        ? "table-light contractListRow"
        : "contractListRow";
    }
  };

  filterContractList = (event) => {
    const searchText = event.target.value?.toUpperCase();
    if (searchText.length > 0) {
      let filteredContractList = this.props.contractList.filter((contract) => {
        return contract.attributes.custom.contractNumberStr
          ?.toUpperCase()
          .includes(searchText);
      });
      this.setState({
        contractDisplayList: filteredContractList,
      });
    } else {
      this.setState({ contractDisplayList: this.props.contractList });
    }
  };

  renderNewContract = () => {
    const { handleNewContractSelect, newContract } = this.props;
    if (Object.keys(newContract).length > 0) {
      const { contractNumber, coverage } = newContract;
      const { description } = coverage;
      return (
        <tr
          onClick={() => handleNewContractSelect(newContract)}
          className={this.getNewContractVariant(newContract)}
          key={contractNumber}
        >
          <td>{contractNumber}</td>
          <td>{description}</td>
        </tr>
      );
    } else {
      return null;
    }
  };

  renderContracts = () => {
    const { handleContractSelect, newContract } = this.props;
    const { contractDisplayList } = this.state;
    let sortedList = contractDisplayList;
    if (contractDisplayList.length > 1) {
      sortedList = contractDisplayList.sort((a, b) =>
        a.attributes.custom.contractNumberStr <
          b.attributes.custom.contractNumberStr
          ? 1
          : a.attributes.custom.contractNumberStr >
            b.attributes.custom.contractNumberStr
            ? -1
            : 0
      );
    }

    if (Object.keys(sortedList).length > 0) {
      return sortedList.map((contract) => {
        return (
          <tr
            onClick={() =>
              handleContractSelect(contract.attributes.custom.contractNumberStr)
            }
            className={this.getVariant(
              contract.attributes.custom.contractNumberStr
            )}
            key={contract.attributes.custom.contractNumberStr}
          >
            <td>{contract.attributes.custom.contractNumberStr}</td>
            <td>
              {contract.attributes.custom.coverageDescriptionTxt.substring(
                0,
                50
              )}
            </td>
          </tr>
        );
      });
    } else {
      if (
        Object.keys(sortedList).length === 0 &&
        Object.keys(newContract).length === 0
      ) {
        return (
          <tr>
            <td colSpan={2}>There are no contracts for this customer.</td>
          </tr>
        );
      }
    }
  };

  render() {
    return (
      <Fragment>
        {/* <Row className="ps-3">
          <Col className="ps-0">
            <h2>Contracts</h2>
          </Col> */}
        {/* <Col>
            <Form.Control
              type="text"
              placeholder="Search by Contract Number"
              onChange={(event) => {
                this.filterContractList(event);
              }}
              onSelect={(event) => {
                this.filterContractList(event);
              }}
            />
          </Col> */}
        {/* </Row> */}
        {/* <Row>
          <Col md={12}>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Contract</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {this.renderNewContract()}
                {this.renderContracts()}
              </tbody>
            </Table>
          </Col>
        </Row> */}
      </Fragment>
    );
  }
}

export default ContractList;
