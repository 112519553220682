import React, { Component } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import {
  Col,
  Row,
  Spinner,
  Card,
  Modal,
  Form,
  CloseButton,
} from "react-bootstrap";
import Calender from "../img/delivery-box (1) 1.svg";
import moment from "moment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const scrollbar = {
  "&::-webkit-scrollbar": {
    width: "5px", // Set the width of the scrollbar
    borderRadius: "5px" /* Add rounded corners */,
    backgroundColor: "#f0f0f0" /* Set the background color of the track */,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888", // Set the color of the scrollbar thumb
    borderRadius: "5px", // Set the border radius of the thumb
  },
};
export default class ConfirmationDialog extends Component {
  state = {
    reasonCode: "",
    finalBookingTimeSlot: "",
    dropDownAvailability: false,
    bookingNote: "",
    timeband: [],
  };

  handleAvailabilityRadio(event) {
    this.setState({
      dropDownAvailability: true,
      finalBookingTimeSlot: event.target.value,
    });
  }
  componentDidMount() {
    this.setState({
      reasonCode: "",
      finalBookingTimeSlot: "",
      dropDownAvailability: false,
      bookingNote: "",
    });
    if (
      this.props.bookingContent.serviceProvider !== "A&E" &&
      this.props.bookingContent.provider !== "A&E"
    ) {
      if (this.props.bookingContent.timeSlot) {
        const dummy = this.props.bookingContent.timeSlot;
        const orderMapping = { morning: 0, evening: 2, afternoon: 1 };
        function customSort(a, b) {
          return orderMapping[a.slotName] - orderMapping[b.slotName];
        }
        dummy.sort(customSort);
        this.setState({ timeband: dummy });
      } else {
        const dummy = this.props.bookingContent.timeBand;
        const orderMapping = { morning: 0, evening: 2, afternoon: 1 };
        function customSort(a, b) {
          return orderMapping[a] - orderMapping[b];
        }
        dummy.sort(customSort);
        this.setState({ timeband: dummy });
      }
    }
  }

  handleDisabled() {
    if (
      this.props.softOrForceBook === "soft" ||
      this.props.softOrForceBook === "firm"
    ) {
      if (this.props.servicerIndex !== 0) {
        if (
          this.state.finalBookingTimeSlot !== "" &&
          this.state.reasonCode !== ""
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (this.state.finalBookingTimeSlot === "") {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (this.state.finalBookingTimeSlot === "") {
        return true;
      } else {
        return false;
      }
    }
  }

  render() {
    const {
      handleConfirmDialog,
      bookingContent,
      reasonList,
      servicerIndex,
      handleClose,
      handleBookAppointment,
      handleForceBook,
      softOrForceBook,
    } = this.props;
    const CardsContetnStyle = {
      fontSize: "12px",
    };
    return (
      <>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={handleConfirmDialog}
          PaperProps={{
            style: {
              borderRadius: "20px",
              width: "29rem",
              height: "50rem",
            },
          }}
        >
          <DialogTitle
            style={{ textAlign: "center" }}
            sx={{ m: 3, p: 2, mb: 0, pb: 0 }}
            id="customized-dialog-title"
          >
            <img
              src={Calender}
              alt="user-pic"
              height="45px"
              width="45px"
              style={{
                background: "rgba(235, 248, 255, 1)",
                padding: "10px",
                borderRadius: "48%",
              }}
            />
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 15,
              height: "25px",
              width: "25px",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ textAlign: "center", ...scrollbar }}>
            <Grid>
              <Grid
                item
                xs={12}
                style={{ justifyContent: "center", marginTop: "1.8rem" }}
              >
                <Grid>
                  <span style={{ fontSize: "15px" }}>
                    Do you want to schedule appointment for <br /> for{" "}
                    {bookingContent?.day}, {bookingContent?.availableDate}.
                  </span>
                </Grid>

                <Grid
                  style={{
                    width: "90%",
                    margin: "auto",
                    marginTop: "20px",
                    marginBottom: "25px",
                    borderRadius: "20px",
                  }}
                >
                  <Paper
                    elevation={0}
                    style={{ borderRadius: "18px", overflow: "hidden" }}
                  >
                    <Grid
                      style={{
                        textAlign: "center",
                        height: "2.6rem",
                        background: "rgba(0, 161, 255, 1)",
                        color: "white",
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {bookingContent?.serviceProvider}
                    </Grid>
                    <Grid
                      style={{
                        background: "rgba(242, 244, 255, 1)",
                        padding: "1rem",
                        textAlign: "left",
                      }}
                    >
                      <span style={CardsContetnStyle}>
                        <b>Dispatch Contact : </b>{" "}
                        <span
                          style={{ ...CardsContetnStyle, color: "#02A0FC" }}
                        >
                          {bookingContent?.dispatchMethodPhone}{" "}
                          {bookingContent?.dispatchMethodEmail && (
                            <>
                              <br />
                              {bookingContent?.dispatchMethodEmail}
                            </>
                          )}
                        </span>
                      </span>
                      <br />
                      <span style={CardsContetnStyle}>
                        <b> Dispatch Address :</b>{" "}
                        {bookingContent?.dispatchAddress}
                      </span>
                      <br />
                      <span style={CardsContetnStyle}>
                        <b>Notes : </b> <span>{bookingContent?.notes}</span>
                      </span>
                      <br />
                    </Grid>
                  </Paper>
                </Grid>

                <Grid
                  container
                  xs={12}
                  style={{ flexWrap: "wrap", justifyContent: "space-around" }}
                >
                  <br />

                  <Grid item xs={3} sx={{ marginTop: "8px" }}>
                    <span style={CardsContetnStyle}>
                      <b> Availability : </b>
                    </span>
                  </Grid>
                  <Grid container xs={8}>
                    <RadioGroup
                      name="options"
                      value={this.state.finalBookingTimeSlot}
                      onChange={(event) => this.handleAvailabilityRadio(event)}
                      row
                    >
                      <Grid container xs={12}>
                        {bookingContent?.svcrType
                          ? Array.isArray(
                              bookingContent?.timeSlot
                                ? bookingContent?.timeSlot
                                : bookingContent?.timeBand
                            ) &&
                            (bookingContent?.timeSlot
                              ? bookingContent?.timeSlot
                              : bookingContent?.timeBand
                            ).map((time) => (
                              <Grid
                                item
                                xs={6}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                                sx={{ margin: 0 }}
                              >
                                <FormControlLabel
                                  sx={{ margin: 0 }}
                                  value={time.slotName}
                                  control={<Radio />}
                                  label={
                                    <span
                                      style={{ fontSize: "12px", margin: 0 }}
                                    >
                                      {time.slotName.charAt(0).toUpperCase() +
                                        time.slotName.slice(1)}
                                    </span>
                                  }
                                />
                              </Grid>
                            ))
                          : Array.isArray(bookingContent?.timeBand) &&
                            bookingContent?.timeBand.map((time) => (
                              <Grid
                                item
                                xs={6}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                                sx={{ margin: 0 }}
                              >
                                <FormControlLabel
                                  sx={{ margin: 0 }}
                                  value={time}
                                  control={<Radio />}
                                  label={
                                    <span
                                      style={{ fontSize: "12px", margin: 0 }}
                                    >
                                      {time.charAt(0).toUpperCase() +
                                        time.slice(1)}
                                    </span>
                                  }
                                />
                              </Grid>
                            ))}
                      </Grid>
                    </RadioGroup>
                  </Grid>
                </Grid>

                {servicerIndex != 0 &&
                  softOrForceBook !== "force" &&
                  this.state.dropDownAvailability && (
                    <>
                      <Grid style={{ marginTop: ".5rem", fontSize: "12px" }}>
                        <span>
                          Please select a reason for choosing this servicer
                        </span>
                      </Grid>
                      <Grid style={{ width: "90%", margin: "auto" }}>
                        <FormControl
                          style={{ marginTop: "1rem" }}
                          fullWidth
                          size="small"
                        >
                          <Select
                            value={
                              this.state.reasonCode ? this.state.reasonCode : ""
                            }
                            onChange={(event) => {
                              const reason = event.target.value;
                              this.setState({ reasonCode: reason });
                            }}
                            style={{ fontSize: "12px" }}
                          >
                            {reasonList?.map((value) => (
                              <MenuItem
                                key={value.reasonCode}
                                value={value.reasonCode}
                                // sx={{ ...StyledScrollbar, fontSize: "12px" }}
                                sx={{ fontSize: "12px" }}
                              >
                                {value.reasonName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                <p
                  style={{
                    "font-size": "0.6rem",
                    "font-weight": "bold",
                    "margin-bottom": "5px",
                    "margin-top": "10px",
                    "text-align": "center",
                  }}
                >
                  <b>Enter Notes Here</b>
                </p>
                <Col xs={12}>
                  {/* <Form.Control
                                            type="text"
                                            placeholder="Enter Notes Here"
                                            value={this.state.bookingNote}
                                            onFocus={(event) => { debugger }}
                                            onChange={(event) => {
                                                debugger
                                                this.setState({ bookingNote: event.target.value })
                                            }}
                                            size="md"
                                            tabIndex="7"
                                        /> */}
                  <TextField
                    placeholder="Enter Notes Here"
                    value={this.state.bookingNote}
                    onChange={(event) => {
                      this.setState({ bookingNote: event.target.value });
                    }}
                  />
                </Col>
                <Grid style={{ marginTop: "1.8rem" }}>
                  <Button
                    variant="contained"
                    disabled={this.handleDisabled()}
                    onClick={() => {
                      if (bookingContent?.svcrType) {
                        handleBookAppointment(
                          bookingContent,
                          this.state.bookingNote,
                          this.state.reasonCode,
                          this.state.finalBookingTimeSlot
                        );
                      } else {
                        handleForceBook(
                          bookingContent,
                          this.state.bookingNote,
                          this.state.reasonCode,
                          this.state.finalBookingTimeSlot
                        );
                      }
                    }}
                    style={{ marginLeft: "20px" }}
                  >
                    Proceed
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </>
    );
  }
}
