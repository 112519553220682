import React, { Component } from "react";
import moment from "moment";
import { Row, Card, Col, ListGroup, Accordion } from "react-bootstrap";
import Appointment from "./Appointment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight
} from '@fortawesome/free-solid-svg-icons'

class AppointmentDay extends Component {
  state = {
    appintments: []
  };

  componentDidMount() {
    const { date } = this.props;
    const { appointmentList } = date;
    this.setState({
      appintments: appointmentList || []
    });
  }

  getWeekDay() {
    let dayOfWeek = moment(this.props.date.date).day();
    switch (dayOfWeek) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        return "";
    }
  }

  displayAppointments(appintments) {
    const {
      bookedAppointment,
      date,
      handleAppointmentSelect,
      selectedAppointment,
      showPopupAppointmentDetail
    } = this.props;

    let groupedAppointment = []
    for (const item of appintments) {
      let index = groupedAppointment.findIndex(x => x.svcrName === item.appointmentInfo.svcrName)
      if (index >= 0) {
        groupedAppointment[index].appointments.push(item)
      } else {
        groupedAppointment.push({
          // type: item.appointmentInfo.type,
          type: item.appointmentInfo.svcrType,
          svcrName: item.appointmentInfo.svcrName,
          appointments: [item]
        })
      }
    }
    return groupedAppointment.map((appintment, i) => {
      return (
        <Appointment
          appointment={appintment}
          handleAppointmentSelect={handleAppointmentSelect}
          selectedAppointment={selectedAppointment}
          bookedAppointment={bookedAppointment}
          type={appintment.type}
          servicer={appintment.svcrName}
          appointments={appintment.appointments}
          index={i}
          showPopupAppointmentDetail={showPopupAppointmentDetail}
        />
      );
    });
  }

  render() {
    const {
      appintments
    } = this.state;

    const {
      bookedAppointment,
      date,
      handleAppointmentSelect,
      selectedAppointment,
      handleNextWeek,
      handleLastWeek,
      index,
      total,
      showLast
    } = this.props;

    return (
      <Col style={{ "padding-left": "0", "padding-right": "0" }}>
        <Card className="text-center mb-2" text="white" style={{ border: "none", "background-color": "white !important" }}>
          <Card.Header style={{ "font-size": ".7rem", "background-color": "#f2f4ff", "border": "none", "color": "black" }}>
            <Row>
              {showLast && index == 0 && <>
                <Col style={{ "padding-left": "0", "padding-right": "0" }} xs={2}>
                  <FontAwesomeIcon style={{ "margin-top": "15px", "width": "100%", "cursor": "pointer" }} size='1x' icon={faAngleLeft} onClick={() =>
                    handleLastWeek()
                  } />
                </Col>
              </>}
              <Col>
                {this.getWeekDay()}
                <br />
                {date.date}
              </Col>
              {index == total - 1 && <>
                <Col style={{ "padding-left": "0", "padding-right": "0" }} xs={2}>
                  <FontAwesomeIcon style={{ "margin-top": "15px", "width": "100%", "cursor": "pointer" }} size='1x' icon={faAngleRight} onClick={() =>
                    handleNextWeek()
                  } />
                </Col>
              </>}
            </Row>
          </Card.Header>
          <Accordion style={{ "font-size": ".6rem", "margin-top": "10px", "height": "300px", "background-color": "white", "border-right": "0.01rem dashed gray" }}>
            {this.displayAppointments(appintments)}
          </Accordion>
        </Card>
      </Col>
    );
  }
}

export default AppointmentDay;
