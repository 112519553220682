import moment from "moment";
import React, { Component, Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";

class Customer extends Component {
  render() {
    const states = [
      "AL",
      "AK",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "DC",
      "FL",
      "GA",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "OH",
      "OK",
      "OR",
      "PA",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
    ];

    const {
      address,
      address2,
      city,
      email,
      firstName,
      handleInputChange,
      isPostalCodeValid,
      isPostalCodeValidated,
      lastName,
      postalCode,
      primaryPhone,
      secondaryPhone,
      state,
      customerRequestedDate,
      customerRequestedWindow,
      customerRequestedWindowArray,
      handleCustomerRequestedDate,
      symptomCode,
      symptomCodeList,
      failureDt,
      isSymptomCodeValidated,
      isSymptomCodeValid,
      handleFailureDateSelect,
      isFailureNoteValidated,
      isFailureNoteValid,
      failureNote,
      servicerNotes,
    } = this.props;
    return (
      <Fragment>
        {/* <h2>Customer</h2> */}
        {/* <Form.Group as={Row} controlId="firstName">
          <Form.Label column xs={6}>
            First Name:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete="off"
              type="text"
              placeholder="First Name"
              value={firstName}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="lastName">
          <Form.Label column xs={6}>
            Last Name:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder="Last Name"
              value={lastName}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="address">
          <Form.Label column xs={6}>
            Address:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder="Street Address"
              value={address}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="address2">
          <Form.Label column xs={6}>
            Address 2:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder="Additional Address"
              value={address2}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="city">
          <Form.Label column xs={6}>
            City:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder="City"
              value={city}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="state">
          <Form.Label column xs={6}>
            State:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              autoComplete="off"
              as="select"
              size="sm"
              value={state}
              onChange={(event) => {
                handleInputChange(event);
              }}
            >
              <option key="" value="">
                Select state...
              </option>
              {states.map((state, i) => (
                <option key={i} value={state}>
                  {state}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="postalCode">
          <Form.Label column xs={6}>
            Postal Code:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              className={
                !isPostalCodeValidated
                  ? null
                  : isPostalCodeValid
                    ? "is-valid"
                    : "is-invalid"
              }
              autoComplete="off"
              type="text"
              placeholder="Postal Code"
              value={postalCode}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid postal code.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="email">
          <Form.Label column xs={6}>
            Email:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              type="email"
              autoComplete="off"
              placeholder="Email"
              value={email}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="primaryPhone">
          <Form.Label column xs={6}>
            Primary Phone:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder="123-456-7890"
              value={primaryPhone}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="secondaryPhone">
          <Form.Label column xs={6}>
            Secondary Phone:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder="123-456-7890"
              value={secondaryPhone}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            />
          </Col>
        </Form.Group> */}

        <Form.Group
          as={Row}
          controlId="symptomCode"
          className="mb-1"
          style={{ marginTop: "4rem" }}
        >
          <Form.Label column xs={6}>
            Symptom Code:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              className={
                !isSymptomCodeValidated
                  ? null
                  : isSymptomCodeValid
                  ? "is-valid"
                  : "is-invalid"
              }
              as="select"
              value={symptomCode}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            >
              <option value="">Select symptom code...</option>
              {symptomCodeList?.map((symptomCode) => (
                <option
                  key={symptomCode.description}
                  value={symptomCode.description}
                >
                  {symptomCode.description}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please provide a symptom code.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="failureDt">
          <Form.Label column xs={6}>
            Failure Date:
          </Form.Label>
          <Col xs={6}>
            <DatePicker
              as={Form.Control}
              selected={new Date()}
              onSelect={(date) => handleFailureDateSelect(date)}
              value={failureDt}
              maxDate={new Date()}
              disabledKeyboardNavigation
              aria-describedby="failureDtHelpBlock"
              className="custom-datepicker"
            />
            <Form.Text id="failureDtHelpBlock" muted>
              <br></br>If unknown, use today's date.
            </Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="failureNote" className="mb-1">
          <Form.Label column xs={6}>
            Complaint:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="textarea"
              placeholder="failure note"
              value={failureNote}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
              className={
                !isFailureNoteValidated
                  ? null
                  : isFailureNoteValid
                  ? "is-valid"
                  : "is-invalid"
              }
            />
            <Form.Control.Feedback type="invalid">
              Please provide the complaint.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="servicerNotes" className="mb-1">
          <Form.Label column xs={6}>
            Servicer Notes:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="textarea"
              placeholder="notes"
              value={servicerNotes}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="customerRequestedDate">
          <Form.Label column xs={6}>
            Customer Requested Date:
          </Form.Label>
          <Col xs={6}>
            <DatePicker
              as={Form.Control}
              selected={new Date()}
              onSelect={(date) => handleCustomerRequestedDate(date)}
              value={customerRequestedDate}
              minDate={new Date()}
              disabledKeyboardNavigation
              aria-describedby="failureDtHelpBlock"
              className="custom-datepicker"
            />
            <Form.Text id="failureDtHelpBlock" muted>
              <br></br>If unknown, use today's date.
            </Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="customerRequestedSlot">
          <Form.Label column xs={6}>
            Customer Requested Window:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="select"
              value={customerRequestedWindow}
              defaultValue={"Morning"}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            >
              {customerRequestedWindowArray?.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
      </Fragment>
    );
  }
}

export default Customer;
