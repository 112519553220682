import { LogLevel } from "@azure/msal-browser";
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri:process.env.REACT_APP_REDIRECT_URI
        // above for client
        // clientId: process.env.CLIENT_ID,
        //  authority: process.env.AUTHORITY,
        //  redirectUri: process.env.REDIRECT_URI_DEVELOPMENT,
        // env not working --use above
        //MH Secret
        //   clientId: '4ba0639d-2370-462d-b034-c888eccddfba',
        //  authority: 'https://login.microsoftonline.com/9ba8f7bb-191b-409d-bff7-4dfa3f80bc47',
        //   redirectUri: 'http://localhost:3001/'
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

export const loginRequest = {
    scopes: ['https://graph.microsoft.com/.default'],
}

