import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Grid, List, ListItemButton, ListSubheader, } from '@mui/material';
// import "../ServiceOrderDetails/SereviceOrderDetailsStyle.css"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Morning from "../../img/Morning.svg"
import NotMorning from "../../img/Morning-not-available.svg"
import Afternoon from "../../img/Afternoon.svg"
import NotAfterNoon from "../../img/Afternoon-not-available.svg"
import Evening from "../../img/Evening.svg"
import NotEvening from "../../img/Evening-not-available.svg"
import SoftAvailability from "../../img/appointment (1) (2) (1).svg"
import Rejected from "../../img/appointment (1) (2) (1)rejected.svg"
import firm from "../../img/appointmentfirm.svg"

import axios from 'axios';
import toast from 'react-hot-toast';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function ReassignDialog({ totalReassignData, setReassignIndex, setAppointmentDay, setHandleConfirmDialog, setAppointmentDate, setFinalBookingTimeSlot, setFirmAvilabilityDialog, reassignOpen, handleClose, data, setBookingContent, servicerData, setActivefirmAvailabilitydialog, setReassignData, serviceOrder, uiservicerData }) {

    const [openAccordions, setOpenAccordions] = useState([]);
    const [increaseBtnDisable, setIncreaseBtnDisable] = useState(true)
    const [decreaseBtnDisable, setDecreaseBtnDisableBtnDisable] = useState(false)

    const scrollbar = {
        "&::-webkit-scrollbar": {
            width: "5px", // Set the width of the scrollbar
            borderRadius: "5px" /* Add rounded corners */,
            backgroundColor: "#f0f0f0" /* Set the background color of the track */,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888", // Set the color of the scrollbar thumb
            borderRadius: "5px", // Set the border radius of the thumb
        },
    };

    const handleBooking = async (subItems, item, subIndex) => {
        setAppointmentDate(item?.date)
        setAppointmentDay(item?.day)
        setReassignIndex(subIndex)
        if (subItems?.svcrType === "WEB") {
            let res = await axios.get(
                // `${process.env.REACT_APP_API_SDPROVIDER_URL}getWeeklyAvailabilityByServicer?servicerAccountId=${subItems?.servicerAccount}`,
                `${process.env.REACT_APP_API_SDPROVIDER_URL}getSoftAvailabilityServicerDetail?servicerId=${subItems?.servicerId}`
            );

            if (res.data.isSuccess) {
                const newData = { ...subItems, dispatchAddress: res?.data?.data.dispatchAddress, dispatchMethodPhone: res?.data?.data.dispatchMethodPhone, dispatchMethodEmail: res?.data?.data.dispatchMethodEmail, servicerAccountId: res?.data?.data.servicerAccountId, notes: res?.data?.data.notes };
                const bookingData = newData;
                setBookingContent(bookingData)
                setHandleConfirmDialog(true)
                // const bookingData = res?.data?.data[item.day][0]
                // setBookingContent(bookingData)
                // setHandleConfirmDialog(true)
            }
            else {
                toast.error("Something Went Wrong")
            }
        }
        else {
            setBookingContent(subItems)
            setHandleConfirmDialog(true)
        }
    }

    // Example of using the refs in useEffect
    const hanDleBackward = () => {
        const firstSeven = totalReassignData.slice(0, 7);
        setReassignData(firstSeven)
        setIncreaseBtnDisable(true)
        setDecreaseBtnDisableBtnDisable(false)

        // let copyData = [...data]
        // function increaseDateByDays(dateString, days) {
        //     const [year, month, day] = dateString.split('-');
        //     const currentDate = new Date(`${month}-${day}-${year}`);
        //     currentDate.setDate(currentDate.getDate() - days);

        //     const newDay = currentDate.getDate();
        //     const newMonth = currentDate.getMonth() + 1;
        //     const newYear = currentDate.getFullYear();

        //     // Ensure leading zeros for day and month
        //     const formattedDay = newDay < 10 ? `0${newDay}` : newDay;
        //     const formattedMonth = newMonth < 10 ? `0${newMonth}` : newMonth;

        //     return `${newYear}-${formattedMonth}-${formattedDay}`;
        // }

        // const updatedArray = copyData.map(obj => ({
        //     ...obj,
        //     date: increaseDateByDays(obj.date, 7)
        // }));

        // setReassignData(updatedArray)

    }
    const hanDleForward = () => {
        const lastSeven = totalReassignData.slice(-7)
        setReassignData(lastSeven)
        setIncreaseBtnDisable(false)
        setDecreaseBtnDisableBtnDisable(true)
        // let copyData = [...data]

        // function increaseDateByDays(dateString, days) {
        //     const [year, month, day] = dateString.split('-');
        //     const currentDate = new Date(`${month}-${day}-${year}`);
        //     currentDate.setDate(currentDate.getDate() + days);

        //     const newDay = currentDate.getDate();
        //     const newMonth = currentDate.getMonth() + 1;
        //     const newYear = currentDate.getFullYear();

        //     // Ensure leading zeros for day and month
        //     const formattedDay = newDay < 10 ? `0${newDay}` : newDay;
        //     const formattedMonth = newMonth < 10 ? `0${newMonth}` : newMonth;

        //     return `${newYear}-${formattedMonth}-${formattedDay}`;
        // }

        // const updatedArray = copyData.map(obj => ({
        //     ...obj,
        //     date: increaseDateByDays(obj.date, 7)
        // }));

        // setReassignData(updatedArray)

    }
    const isTimeSlotPresent = (availability, str) => {
        return availability && availability.some(slot => slot.slotName === str);
    }
    return (
        <>
            {
                servicerData?.bookingType !== "firm" && (
                    <BootstrapDialog
                        //   onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={reassignOpen}
                        PaperProps={{
                            sx: {
                                maxWidth: '100vw', // or 'lg', 'xl', etc.
                                width: '100vw', // Set width as needed
                                height: "97vh",
                                borderRadius: "20px",
                                paddingRight: "1rem",
                                paddingLeft: "1rem",
                            },
                        }}
                    >

                        <DialogTitle sx={{ m: 0, p: 2, textAlign: "center", fontSize: "14px", fontWeight: "600", lineHeight: "28px" }} id="customized-dialog-title">
                            Reassign Servicer <br />
                            (Current Service Provider : {uiservicerData?.account?.businessName}-{serviceOrder?.servicerAccountId}, {serviceOrder?.appointmentDay} {serviceOrder?.appointmentDate}, {serviceOrder?.appointmentTimeSlot})
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <DialogContent sx={{ ...scrollbar, overflowY: "scroll", }}>
                            <Grid container xs={12} style={{ height: "100%", justifyContent: "center" }}>
                                {
                                    data?.map((item, index) => (
                                        <Grid key={index} item xs={1.7} style={{ borderLeft: ".5px dotted #EFEFEF", borderRight: ".5px dotted #EFEFEF", borderBottom: ".5px dotted #EFEFEF" }}>
                                            <List
                                                sx={{ ...scrollbar, overflowY: "hidden", width: '100%', maxWidth: 360, bgcolor: 'background.paper', padding: "0", position: "relative" }}
                                                component="nav"
                                                aria-labelledby="nested-list-subheader"
                                                subheader={
                                                    <ListSubheader style={{ background: "#F2F4FF", textAlign: "center", fontSize: "14px", position: "sticky", top: 0, lineHeight: "1.8rem" }} component="div" id="nested-list-subheader">
                                                        {index == 0 && (<ArrowBackIosNewIcon style={{ fontSize: "12px", position: "absolute", left: 20, top: 20 }} onClick={() => decreaseBtnDisable && hanDleBackward()} />)}
                                                        {item.day} <br />
                                                        {item.date}
                                                        {index == (data.length - 1) && (<ArrowForwardIosIcon style={{ fontSize: "12px", position: "absolute", right: 20, top: 20 }} onClick={() => increaseBtnDisable && hanDleForward()} />)}
                                                    </ListSubheader>

                                                }
                                            >
                                                {
                                                    item.availability.map((subItems, subIndex) => (
                                                        <ListItemButton style={{ marginTop: "1rem", width: "100%", paddingLeft: "4px", paddingRight: "4px", paddingBottom: "0px", border: "1px solid rgba(234, 234, 234, 1)" }}>
                                                            <Grid container xs={12}>
                                                                <Grid container item xs={12} sx={{ padding: "0px" }} style={{ alignItems: "center" }}>
                                                                    <Grid item xs={3}>
                                                                        {
                                                                            subItems?.isRejected ? (
                                                                                <img src={Rejected} alt='user-pic' height="24px" width="24px" />
                                                                            ) : (subItems?.serviceProvider === "A&E" && !subItems?.isRejected) ? (
                                                                                <img src={firm} alt='user-pic' height="24px" width="24px" />
                                                                            ) : (
                                                                                <img src={SoftAvailability} alt='user-pic' height="24px" width="24px" />
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={1}>

                                                                    </Grid>
                                                                    <Grid item xs={6} >
                                                                        <Typography onClick={() => handleBooking(subItems, item, subIndex)} sx={{ fontSize: "11px", wordWrap: "break-word", color: 'rgba(2, 160, 252, 1)', cursor: "pointer" }}>
                                                                            {subItems?.serviceProvider}
                                                                        </Typography>

                                                                    </Grid>
                                                                    <Grid item xs={2}>

                                                                    </Grid>


                                                                </Grid>
                                                                <Grid container item xs={12} style={{ alignItems: "top" }}>

                                                                    {
                                                                        (subItems?.serviceProvider != "A&E" && subItems?.provider !== "A&E") ? (
                                                                            <>
                                                                                <Grid item>
                                                                                    <span style={{ fontSize: "12px" }}>Availability : </span>
                                                                                </Grid>
                                                                                <Grid item style={{ alignItems: "top" }}>
                                                                                    {
                                                                                        isTimeSlotPresent(subItems?.timeSlot, "morning") ? (
                                                                                            <Grid item style={{ marginLeft: "3px", marginTop: "3px" }}>
                                                                                                <img src={Morning} alt='user-pic' height="24px" width="24px" />
                                                                                            </Grid>
                                                                                        ) :
                                                                                            (
                                                                                                <Grid item style={{ marginLeft: "3px", marginTop: "3px" }}>
                                                                                                    <img src={NotMorning} alt='user-pic' height="24px" width="24px" />
                                                                                                </Grid>
                                                                                            )
                                                                                    }
                                                                                </Grid>
                                                                                <Grid item style={{ alignItems: "top" }}>
                                                                                    {
                                                                                        isTimeSlotPresent(subItems?.timeSlot, "afternoon") ? (
                                                                                            <Grid item style={{ marginLeft: "3px", marginTop: "3px" }}>
                                                                                                <img src={Afternoon} alt='user-pic' height="24px" width="24px" />
                                                                                            </Grid>
                                                                                        ) :
                                                                                            (
                                                                                                <Grid item style={{ marginLeft: "3px", marginTop: "3px" }}>
                                                                                                    <img src={NotAfterNoon} alt='user-pic' height="24px" width="24px" />
                                                                                                </Grid>
                                                                                            )
                                                                                    }
                                                                                </Grid>
                                                                                <Grid item style={{ alignItems: "top" }}>
                                                                                    {
                                                                                        isTimeSlotPresent(subItems?.timeSlot, "evening") ? (
                                                                                            <Grid item style={{ marginLeft: "3px", marginTop: "3px" }}>
                                                                                                <img src={Evening} alt='user-pic' height="24px" width="24px" />
                                                                                            </Grid>
                                                                                        ) :
                                                                                            (
                                                                                                <Grid item style={{ marginLeft: "3px", marginTop: "3px" }}>
                                                                                                    <img src={NotEvening} alt='user-pic' height="24px" width="24px" />
                                                                                                </Grid>
                                                                                            )
                                                                                    }
                                                                                </Grid>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Grid item>
                                                                                    <span style={{ fontSize: "12px" }}>Availability : </span>
                                                                                </Grid>
                                                                                {
                                                                                    subItems?.timeSlot?.map((timeData) => (
                                                                                        <Grid item style={{ alignItems: "top" }}>
                                                                                            <Grid style={{ border: "1px solid rgba(234, 234, 234, 1)", borderRadius: "8px", background: "rgba(245, 245, 245, 1)", marginLeft: "5px", marginBottom: "10px", padding: "0 3px", height: "24px" }}>
                                                                                                <span style={{ fontSize: "11px" }}> {timeData?.slotName}</span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    ))
                                                                                }

                                                                            </>
                                                                        )
                                                                    }
                                                                </Grid>
                                                                {
                                                                    (subItems?.sameDayCutOff || subItems?.nextDayCutOff) && (
                                                                        <>

                                                                            <Grid container item xs={12} style={{ alignItems: "top", flexWrap: "wrap" }}>
                                                                                {
                                                                                    subItems?.sameDayCutOff && (
                                                                                        <Grid xs={6} item>
                                                                                            {/* <span style={{ fontSize: '8px' }}>   Same Day Cut-Off: {subItems.sameDayCutOff} </span> */}
                                                                                        </Grid>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    subItems.nextDayCutOff && (
                                                                                        <Grid xs={6} item>
                                                                                            {/* <span style={{ fontSize: '8px' }}> Next Day CutOff: {subItems.nextDayCutOff}</span> */}
                                                                                        </Grid>
                                                                                    )
                                                                                }
                                                                            </Grid>
                                                                        </>
                                                                    )
                                                                }
                                                            </Grid>
                                                            {/* <Accordion elevation={0} style={{ border: "1px solid #EAEAEA", paddingBottom: "0px" }}
                                                                                                                            onChange={() => handleAccordionChange(index)}
                                                                                                                            expanded={isAccordionOpen(index)}
                                                                                                                        >
                                                                                                                            <AccordionSummary
                                                                                                                                expandIcon={
                                                                                                                                    isAccordionOpen(index) ? (
                                                                                                                                        <RemoveCircleOutlineOutlinedIcon sx={{ color: '#02A0FC', fontSize: '15px' }} />
                                                                                                                                    ) : (
                                                                                                                                        <AddCircleOutlineOutlinedIcon sx={{ color: '#02A0FC', fontSize: '15px' }} />
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                sx={{ paddingLeft: "4px", paddingRight: "4px" }}
                                                                                                                                aria-controls="panel1a-content"
                                                                                                                                id="panel1a-header"
                                                                                                                            >
                                                                                                                                <Grid container xs={12} sx={{ padding: "0px" }} style={{ alignItems: "center" }}>
                                                                                                                                    <Grid item xs={3}>
                                                                                                                                        <img src="/appointment (1) (2) (1).svg" alt='user-pic' height="24px" width="24px" />
                                                                                                                                    </Grid>
                                                                                                                                    <Grid item xs={9}>
                                                                                                                                        <Typography sx={{ fontSize: "11px", wordWrap: "break-word" }}>
                                                                                                                                            {subItems.svcrName}
                                                                                                                                        </Typography>
                                                            
                                                                                                                                    </Grid>
                                                            
                                                                                                                                </Grid>
                                                            
                                                                                                                            </AccordionSummary>
                                                                                                                            <Divider style={{ width: "100%" }} />
                                                                                                                            <Grid xs={12} sx={{ padding: "8px", wordWrap: 'break-word', width: "100%" }}>
                                                                                                                                <span style={CardsContetnStyle}>
                                                                                                                                    Account ID :  {subItems?.timeSlotData?.svcrID}
                                                                                                                                </span>
                                                                                                                                <br />
                                                                                                                                <span style={CardsContetnStyle}>
                                                                                                                                    Dispatch Method : {subItems?.svcrPhone} {subItems?.svcrPhone && <><br/>{subItems?.svcrPhone}</>}
                                                                                                                                </span>
                                                                                                                                <br />
                                                                                                                                <Grid xs={12} style={{width:"98%",wordWrap: 'break-word', overflowWrap: 'break-word'}}>
                                                                                                                                    <span style={CardsContetnStyle}>
                                                                                                                                        Dispatch Adress :  <span style={{ ...CardsContetnStyle, color: "#02A0FC" }}>{subItems?.svcrAddress1}{subItems?.svcrAddress1 && (<>,<br/>{subItems?.svcrAddress2}</>)}{subItems?.svcrCity && (<>,{subItems?.svcrCity}</>)}{subItems?.svcrState && (<>,{subItems?.svcrState}</>)}{subItems?.svcrCountry && (<>,<br/>{subItems?.svcrCountry}</>)}</span>
                                                                                                                                    </span>
                                                                                                                                </Grid>
                                                                                                                                <br />
                                                                                                                                <span style={CardsContetnStyle}>
                                                                                                                                    Availability :  {subItems?.timeband && subItems?.timeband.map((item2) => (
                                                                                                                                        <> <span onClick={() => { handleBooking(subItems,item2,item) }} style={{ ...CardsContetnStyle, textTransform: "capitalize", background: " rgba(87, 189, 248, 0.16)", color: "rgba(2, 160, 252, 1)", borderRadius: "5px", padding: "1px" }}> {item2} </span>&nbsp;&nbsp;</>
                                                                                                                                    ))}
                                                                                                                                </span>
                                                                                                                                <br />
                                                            
                                                                                                                            </Grid>
                                                                                                                        </Accordion> */}
                                                        </ListItemButton>
                                                    ))
                                                }

                                            </List>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </DialogContent>
                        <DialogActions style={{ paddingBottom: "2rem" }}>
                            <Grid container xs={12} style={{ fontSize: "14px", alignItems: "center" }}>
                                <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                                    <img src={SoftAvailability} alt='user-pic' height="24px" width="24px" />
                                    &nbsp;&nbsp; Soft Availability
                                </Grid>
                                <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                                    <img src={firm} alt='user-pic' height="24px" width="24px" />
                                    &nbsp; &nbsp; Firm Availability
                                </Grid>
                                <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                                    <img src={Rejected} alt='user-pic' height="24px" width="24px" />
                                    &nbsp;&nbsp; Rejected
                                </Grid>
                            </Grid>

                        </DialogActions>

                    </BootstrapDialog>
                )
            }
            {
                servicerData.bookingType == "firm" && (
                    <BootstrapDialog
                        aria-labelledby="customized-dialog-title"
                        open={reassignOpen}
                        PaperProps={{
                            sx: {
                                maxWidth: '28rem',
                                width: '28rem',
                                height: "23rem",
                                borderRadius: "20px",
                                paddingRight: "1rem",
                                paddingLeft: "1rem"
                            },
                        }}
                    >

                        <DialogTitle sx={{ m: 0, p: 2, textAlign: "center", fontSize: "16px", fontWeight: "600", lineHeight: "28px", color: "red" }} id="customized-dialog-title">
                            Attention
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <DialogContent >
                            <Grid style={{ height: "5rem", borderRadius: "15px", justifyContent: "center", alignItems: "center", display: "flex", textAlign: "center", fontSize: "14px", background: "rgba(229, 102, 79, 0.12)", paddingLeft: "1rem", paddingRight: ".6rem" }}>
                                <span style={{ fontSize: "14px" }}>
                                    Current Service Provider for Servicer Order ID {serviceOrder.serviceOrderId} is A&E Factory Service
                                </span>
                            </Grid>
                            <Grid style={{ marginTop: "1rem", height: "5rem", borderRadius: "15px", justifyContent: "center", alignItems: "center", display: "flex", textAlign: "center", fontSize: "14px" }}>
                                <span style={{ fontSize: "14px" }}>
                                    Are you sure you want to reassign a non A&E Servicer to the Service Order ID {serviceOrder.serviceOrderId} ?
                                </span>
                            </Grid>

                        </DialogContent>
                        <DialogActions style={{ paddingBottom: "2rem" }}>
                            <Grid container xs={12} style={{ justifyContent: "center", fontSize: "14px", alignItems: "center", textTransform: "none" }}>
                                <Button variant="contained" onClick={() => {
                                    setFirmAvilabilityDialog(true)
                                    handleClose()
                                }}>
                                    <span style={{ textTransform: "none" }}>Not Sure</span>
                                </Button>
                                <Button variant="outlined" onClick={() => {
                                    handleClose(false)
                                    setActivefirmAvailabilitydialog(2)
                                    setFirmAvilabilityDialog(true)
                                }} style={{ marginLeft: "20px" }}>
                                    <span style={{ textTransform: "none" }}> Sure</span>
                                </Button>
                            </Grid>

                        </DialogActions>

                    </BootstrapDialog>
                )
            }


        </>
    )
}

export default ReassignDialog