import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// import Typography from '@mui/material/Typography';
// import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Grid } from '@mui/material';
import Icon from "../../img/delete 1FInalConfirmation.svg"
// import axios from 'axios';
// import { serviceOrder } from '../../utils/Head';
// import { toast } from 'react-hot-toast';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


export default function FinalConfirmationDialog({ failureId, consumerDetails, bookingContent, finalConfirmDialog, setFinalConfirmDialog, appointmentDay, appointmentDate, finalBookingTimeSlot, closeReassignDialogParent, getServicerOrderParent }) {
    const handleCloseAll = async () => {
        closeReassignDialogParent()
        getServicerOrderParent()
        setFinalConfirmDialog(false)
    }

    const handleClose = () => {
        setFinalConfirmDialog(false)
    };

    const changeDateFormat = () => {
        const [year, month, day] = appointmentDate.split('-');

        // Convert the month number to its corresponding full month name
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const fullMonthName = monthNames[parseInt(month, 10) - 1];

        // Construct the formatted date string
        const formattedDate = `${fullMonthName} ${parseInt(day, 10)}, ${year}`;
        return formattedDate
    }

    return (
        <React.Fragment>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={finalConfirmDialog}

                PaperProps={{
                    style: {
                        borderRadius: '20px',
                        width: "29rem",
                        height: "33rem"
                    },
                }}
            >
                <DialogTitle style={{ textAlign: "center" }} sx={{ m: 2, p: 2, mb: 0 }} id="customized-dialog-title">
                    <img src={Icon} alt='user-pic' height="45px" width="45px" style={{ background: "rgba(235, 248, 255, 1)", padding: "10px", borderRadius: "50%" }} />
                    {/* <EventAvailableIcon sx={{ fonSize: "100px", height: "45px", width: "45px" }} /> */}
                </DialogTitle>

                <DialogContent sx={{ textAlign: "center" }} >
                    <Grid >
                        <span style={{ fontWeight: "600", fontSize: "15px" }}>
                            {bookingContent?.serviceProvider} has been re-assigned to
                            Service Order #{failureId}
                            &nbsp; for {appointmentDay}, {changeDateFormat(appointmentDate)}&nbsp;
                            {finalBookingTimeSlot}.
                        </span>
                    </Grid>

                    <Grid style={{ marginTop: "2rem" }}>
                        <span style={{ fontSize: "12px" }}>
                            Reassigned notification has been sent to Service Provider
                            &nbsp; <span style={{ fontSize: "12px", fontWeight: "600" }}>

                                {bookingContent?.serviceProvider}   &nbsp;
                            </span>
                            at   &nbsp;
                            <span style={{ fontSize: "12px", fontWeight: "600" }}>

                                {bookingContent?.dispatchMethodPhone && (
                                    <>
                                        <br />
                                        {bookingContent?.dispatchMethodPhone}
                                    </>
                                )}
                                {bookingContent?.dispatchMethodEmail && (
                                    <>
                                        <br />
                                        {bookingContent?.dispatchMethodEmail}
                                    </>
                                )}
                            </span>
                        </span>
                    </Grid>

                    <Grid style={{ marginTop: "2rem" }}>
                        <span style={{ fontSize: "12px" }}>
                            Reassigned notification has been sent to Consumer  &nbsp;
                            <span style={{ fontSize: "12px", fontWeight: "600" }}>

                                {consumerDetails?.customerName}   &nbsp;
                            </span>
                            at   &nbsp;
                            <span style={{ fontSize: "12px", fontWeight: "600" }}>
                                {consumerDetails?.phone && (
                                    <>
                                        <br />
                                        {consumerDetails?.phone}
                                    </>
                                )}
                                {consumerDetails?.email && (
                                    <>
                                        <br />
                                        {consumerDetails?.email}
                                    </>
                                )}
                            </span>
                        </span>
                    </Grid>

                    <br />
                    <Grid item xs={12} style={{ justifyContent: "center", marginTop: "1.8rem" }}>

                        <Button variant="contained" onClick={handleCloseAll}>
                            Okay
                        </Button>

                    </Grid>

                </DialogContent>

            </BootstrapDialog>
        </React.Fragment>
    );
}