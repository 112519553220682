import {
  faCheckSquare,
  faFile,
  faSquare,
} from '@fortawesome/free-regular-svg-icons';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parse from 'html-react-parser';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import toast, { Toaster } from 'react-hot-toast';
import {
  getBookedAppointment,
  getExternalPayments,
  getServiceOrderDetails,
  processBuyout,
  processReimbursement,
} from '../api/serviceavenger';
import './ServiceOrderList.css';
import './App.css';
class ServiceOrderList extends Component {
  state = {
    address: '',
    appointmentDetail: null,
    appointmentDt: '',
    appointmentTime: '',
    brand: '',
    buyoutAmount: 0.0,
    buyoutAmountConfirm: 0.0,
    buyoutPassword: '',
    buyoutKey: process.env.REACT_APP_BUYOUT_KEY,
    buyoutKey2: process.env.REACT_APP_BUYOUT_KEY2,
    buyoutKey3: process.env.REACT_APP_BUYOUT_KEY3,
    buyoutKey4: process.env.REACT_APP_BUYOUT_KEY4,
    buyoutKey5: process.env.REACT_APP_BUYOUT_KEY5,
    buyoutKey6: process.env.REACT_APP_BUYOUT_KEY6,
    city: '',
    claimCashDisbursementReason: 'reimbursement',
    claimPayment: 0.0,
    claimStatus: '',
    claimType: '',
    correctiveActionDescription: '',
    countryCode: '',
    dealerPricingGroupNumber: '',
    deductibleDt: '',
    deductibleInput: this.props.deductible,
    dispatchDt: '',
    email: '',
    errorMessage: '',
    failureCode: '',
    failureDescription: '',
    firstName: '',
    foodLoss: 0.0,
    forcedBookingFlag: '',
    freight: 0.0,
    hasSubclaimFlg: false,
    installDt: '',
    isCustomerDispatch: false,
    isCustomerDispatchSubclaim: null,
    isDeductibleRemoved: false,
    isFirstNameValid: null,
    isFirstNameValidated: false,
    isLastNameValid: null,
    isLastNameValidated: false,
    isProcessing: false,
    labor: 0.0,
    lastName: '',
    mileage: 0.0,
    modalErrorMessage: '',
    modelName: '',
    parts: 0.0,
    paymentSource: '',
    phone: '',
    postalCode: '',
    processErrorMessage: '',
    productSequenceNumber: '',
    purchaseDt: '',
    purchasePrice: '',
    repairDt: moment(new Date()).format('MM/DD/YYYY'),
    serial: '',
    serviceOrderNumber: '',
    serviceOrderNumberSub: '',
    serviceOrderDetail: null,
    servicerName: '',
    servicerNumber: '',
    showBuyoutModal: false,
    showServiceOrderModal: false,
    state: '',
    subtotal: 0.0,
    tax: 0.0,
    travel: 0.0,
    verifyEmail: '',
    verifyPhone: '',
    verifyServiceOrderNumberSub: '',
  };

  formatPhone = (phoneString) => {
    let cleanPhoneString = phoneString.replace(/\D/g, '').replace('+', '');
    let phone = cleanPhoneString.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    return phoneString?.length > 9 ? phone : phoneString;
  };

  getAppointment = async (serviceOrderNumber) => {
    const { contractNumber } = this.props;
    let errorMessage = this.state.errorMessage;
    if (contractNumber?.length > 0 && serviceOrderNumber?.length > 0) {
      try {
        const response = await getBookedAppointment(
          contractNumber,
          serviceOrderNumber
        );
        const { status, data } = response;
        if (status === 200) {
          if (data.status !== 200) {
            errorMessage += `<br/><b>Error while getting booked appointment:</b><br/>${data?.message}`;
            let errorDetails = data?.data?.details;

            if (errorDetails?.length > 0) {
              errorMessage += '<ul>';
              errorDetails.forEach((error) => {
                errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
              });
              errorMessage += '</ul>';
            } else {
              errorDetails = data?.data;
              if (errorDetails.length > 0) {
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>';
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[
                      key
                    ].toString()}</li>`;
                  });
                  errorMessage += '</ul>';
                });
              }
            }

            this.setState({ errorMessage });
            window.location.href = '#errorFound';
          } else {
            const appointmentList = data.data;
            if (appointmentList.length > 0) {
              const appointmentDetails = appointmentList[0];
              const {
                appointmentDate,
                appointmentTimeSlot,
                forcedBookingFlag,
                serviceRequestType,
              } = appointmentDetails;

              this.setState({
                appointmentDt:
                  forcedBookingFlag === 'YES'
                    ? 'Forced'
                    : moment.utc(appointmentDate).format('MM/DD/YYYY'),
                appointmentTime:
                  forcedBookingFlag === 'YES' ? 'Forced' : appointmentTimeSlot,
                claimType: serviceRequestType,
                forcedBookingFlag: forcedBookingFlag,
              });

              return appointmentDetails;
            } else {
              this.setState({
                appointmentDt: '',
                appointmentTime: '',
                claimType: '',
                forcedBookingFlag: '',
              });
            }
          }
        } else {
          errorMessage += `<br/><b>Error while getting booked appointment:</b><br/>${status}`;
          this.setState({ errorMessage });
          window.location.href = '#errorFound';
        }
      } catch (error) {
        errorMessage += `<br/><b>Error while getting booked appointment:</b><br/>${error.message}`;
        this.setState({ errorMessage });
        window.location.href = '#errorFound';
      }
    }
  };

  getButton = (serviceOrder) => {
    const { selectedServiceOrder } = this.props;
    if (
      selectedServiceOrder?.serviceOrderNumber ===
      serviceOrder?.serviceOrderNumber &&
      selectedServiceOrder != null
    ) {
      return (
        <Button
          variant="info"
          className="RoundedButton"
          size="md"
          onClick={() => this.props.handleServiceOrderSelect(serviceOrder, 1)}
        >
          <FontAwesomeIcon icon={faCheckSquare} />
          <span className="ps-3">Selected</span>
        </Button>
      );
    } else {
      return (
        <Button
          variant="outline-info"
          className="RoundedButton"
          size="md"
          onClick={() => this.props.handleServiceOrderSelect(serviceOrder, 1)}
        >
          <FontAwesomeIcon icon={faSquare} />
          <span className="ps-3">Select</span>
        </Button>
      );
    }
  };

  getDeductiblePaymentDt = async () => {
    const { contractNumber, externalContractNumber } = this.props;
    let errorMessage = '';
    let deductibleDt = '';
    try {
      let response = {};
      response = await getExternalPayments(
        contractNumber,
        externalContractNumber
      );

      const { status, data, message } = response;

      if (status === 200) {
        if (data.status !== 200) {
          errorMessage += `<b>Error while getting claim details:</b><br/>${data?.status} - ${data?.message}`;
          let errorDetails = data?.data?.details;

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>';
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
            });
            errorMessage += '</ul>';
          } else {
            errorDetails = data?.data;
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            }
          }

          this.setState({ modalErrorMessage: errorMessage });
          window.location.href = '#modalErrorFound';
          return null;
        } else {
          let deductibleDetail =
            response.data.data.length > 0
              ? response.data.data.find((payment) => {
                return (
                  this.state.serviceOrderNumber?.toUpperCase() &&
                  payment.reasonCode?.toUpperCase() ===
                  'DEDUCTIBLE'.toUpperCase()
                );
              })
              : {};
          deductibleDt =
            Object.keys(deductibleDetail).length > 0
              ? new Date(
                deductibleDetail.paymentTransactionDateTime
              ).toLocaleDateString('en-US')
              : '';
        }
        this.setState({ deductibleDt });
      } else {
        errorMessage = this.state.errorMessage;
        errorMessage += `<br/><b>Error while getting claim details:</b><br/> ${status} - ${message}`;
        this.setState({ modalErrorMessage: errorMessage });
        window.location.href = '#modalErrorFound';
        return null;
      }
    } catch (error) {
      errorMessage += `<br/><b>Error while getting booked appointment:</b><br/>${error.message}`;
      this.setState({ modalErrorMessage: errorMessage });
      window.location.href = '#modalErrorFound';
    }
  };

  getServiceOrderSubclaimDetails = async () => {
    const { serviceOrderNumberSub, verifyServiceOrderNumberSub } = this.state;
    if (
      serviceOrderNumberSub?.length > 0 &&
      verifyServiceOrderNumberSub?.length > 0 &&
      serviceOrderNumberSub?.toUpperCase() ===
      verifyServiceOrderNumberSub?.toUpperCase()
    ) {
      try {
        let errorMessage = '';
        let response = {};
        let serviceOrderNumber = this.state.serviceOrderNumberSub;
        response = await getServiceOrderDetails(serviceOrderNumber);
        this.setState({ isProcessing: true, subclaimModalErrorMessage: '' });

        const { status, data, message } = response;

        if (status === 200) {
          if (data.status !== 200) {
            errorMessage += `<b>Error while getting subclaim details:</b><br/>${data?.status} - ${data?.message}`;
            let errorDetails = data?.data?.details;

            if (errorDetails?.length > 0) {
              errorMessage += '<ul>';
              errorDetails.forEach((error) => {
                errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
              });
              errorMessage += '</ul>';
            } else {
              errorDetails = data?.data;
              if (errorDetails.length > 0) {
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>';
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[
                      key
                    ].toString()}</li>`;
                  });
                  errorMessage += '</ul>';
                });
              }
            }

            this.setState({
              isProcessing: false,
              subclaimModalErrorMessage: errorMessage,
            });
            window.location.href = '#subclaimModalErrorFound';
            return null;
          } else {
            const serviceOrderDetail = response.data.data;
            let isCustomerDispatch;
            if (serviceOrderDetail) {
              const { dispatch } = serviceOrderDetail.claim;

              if (dispatch) {
                isCustomerDispatch = dispatch.isCustomerDispatch
                  ? dispatch.isCustomerDispatch
                  : false;
                this.setState({
                  isCustomerDispatchSubclaim: isCustomerDispatch,
                });
              }
            }
            this.setState({ isProcessing: false });
          }
        } else {
          errorMessage = this.state.modalErrorMessage;
          errorMessage += `<br/><b>Error while getting subclaim details:</b><br/> ${status} - ${message}`;
          this.setState({
            isProcessing: false,
            subclaimModalErrorMessage: errorMessage,
          });
          window.location.href = '#subclaimModalErrorFound';
          return null;
        }
      } catch (error) {
        this.setState({
          subclaimModalErrorMessage: `<br/><b>Error while getting subclaim details:</b><br/>${error.message}`,
          isProcessing: false,
        });
        window.location.href = '#subclaimModalErrorFound';
        return null;
      }
    }
  };

  getServiceOrderDetails = async (serviceOrderNumber) => {
    try {
      let errorMessage = '';
      let response = {};
      response = await getServiceOrderDetails(serviceOrderNumber);

      const { status, data, message } = response;

      if (status === 200) {
        if (data.status !== 200) {
          errorMessage += `<b>Error while getting claim details:</b><br/>${data?.status} - ${data?.message}`;
          let errorDetails = data?.data?.details;

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>';
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
            });
            errorMessage += '</ul>';
          } else {
            errorDetails = data?.data;
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>';
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += '</ul>';
              });
            }
          }

          this.setState({ modalErrorMessage: errorMessage });
          window.location.href = '#modalErrorFound';
          return null;
        } else {
          const serviceOrderDetail = response.data.data;
          let dealerPricingGroupNumber, isCustomerDispatch;
          if (serviceOrderDetail) {
            const {
              claimNumber,
              claimStatus,
              contact,
              contractNumber,
              dealer,
              dispatch,
              failure,
              product,
              servicer,
            } = serviceOrderDetail.claim;

            if (dealer) {
              const { pricingGroupNumber } = dealer;
              dealerPricingGroupNumber = pricingGroupNumber;
            }

            if (dispatch) {
              isCustomerDispatch = dispatch.isCustomerDispatch
                ? dispatch.isCustomerDispatch
                : false;
              this.setState({
                dispatchDt:
                  moment.utc(dispatch.date).format('MM/DD/YYYY') !==
                    '01/01/0001'
                    ? moment.utc(dispatch.date).format('MM/DD/YYYY')
                    : '',
                isCustomerDispatch,
              });
            }

            this.setState({
              claimStatus: claimStatus,
              contractNumber: contractNumber,
              dealerPricingGroupNumber,
              serviceOrderDetail,
              serviceOrderNumber: claimNumber,
            });

            if (servicer) {
              const { name, number } = servicer;
              this.setState({ servicerName: name, servicerNumber: number });
            }
            if (contact) {
              const { address, firstName, lastName } = contact;
              this.setState({ firstName, lastName });
              if (address) {
                const { address1, city, countryCode, postalCode, state } =
                  address;
                this.setState({
                  address: address1,
                  city,
                  countryCode,
                  postalCode,
                  state,
                });
              }
            }

            if (failure) {
              const { failureType, failureDescription } = failure;
              this.setState({ failureCode: failureType, failureDescription });
            }
            if (product) {
              const {
                mfg,
                modelName,
                serial,
                productPurchaseDate,
                productSequenceNumber,
                installDate,
                purchasePrice,
              } = product;

              this.setState({
                brand: mfg,
                installDt: installDate,
                modelName,
                productSequenceNumber,
                purchaseDt: productPurchaseDate,
                purchasePrice,
                serial,
              });
            }

            return response.data.data;
          }
          return null;
        }
      } else {
        errorMessage = this.state.modalErrorMessage;
        errorMessage += `<br/><b>Error while getting claim details:</b><br/> ${status} - ${message}`;
        this.setState({ modalErrorMessage: errorMessage });
        window.location.href = '#modalErrorFound';
        return null;
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error while getting claim details:</b><br/>${error.message}`,
      });
      window.location.href = '#modalErrorFound';
      return null;
    }
  };

  getSubcategory = (serviceOrder) => {
    return serviceOrder.subCategory != null &&
      serviceOrder.subCategory.length > 0
      ? serviceOrder.subCategory
      : null;
  };

  getVariant = (serviceOrder) => {
    const { selectedServiceOrder } = this.props;
    if (
      selectedServiceOrder?.serviceOrderNumber ===
      serviceOrder?.serviceOrderNumber &&
      selectedServiceOrder != null
    ) {
      return 'table-active';
    } else {
      return serviceOrder === null ? 'table-light' : '';
    }
  };

  handleBuyoutModalClose = () => {
    this.setState({ showBuyoutModal: false });
    this.handleBuyoutModalReset();
  };

  handleBuyoutModalReset = () => {
    this.setState({
      address: '',
      appointmentDetail: null,
      appointmentDt: '',
      appointmentTime: '',
      brand: '',
      buyoutPassword: '',
      city: '',
      claimCashDisbursementReason: 'reimbursement',
      claimPayment: 0.0,
      claimStatus: '',
      claimType: '',
      correctiveActionDescription: '',
      countryCode: '',
      dealerPricingGroupNumber: '',
      deductibleDt: '',
      deductibleInput: this.props.deductible,
      dispatchDt: '',
      email: '',
      errorMessage: '',
      failureCode: '',
      failureDescription: '',
      firstName: '',
      foodLoss: 0.0,
      forcedBookingFlag: '',
      freight: 0.0,
      installDt: '',
      isCorrectiveActionDescriptionValid: null,
      isCorrectiveActionDescriptionValidated: false,
      isCustomerDispatch: false,
      isDeductibleRemoved: true,
      isEmailValid: null,
      isEmailValidated: false,
      isFirstNameValid: null,
      isFirstNameValidated: false,
      isLastNameValid: null,
      isLastNameValidated: false,
      isPhoneValid: null,
      isPhoneValidated: false,
      isRepairDtValid: null,
      isRepairDtValidated: false,
      labor: 0.0,
      lastName: '',
      mileage: 0.0,
      modalErrorMessage: '',
      modelName: '',
      parts: 0.0,
      paymentSource: 'PayPal',
      phone: '',
      postalCode: '',
      processErrorMessage: '',
      productSequenceNumber: '',
      purchaseDt: '',
      purchasePrice: '',
      repairDt: moment(new Date()).format('MM/DD/YYYY'),
      serial: '',
      serviceOrderNumber: '',
      serviceOrderDetail: null,
      servicerName: '',
      servicerNumber: '',
      showBuyoutModal: false,
      showServiceOrderModal: false,
      state: '',
      subclaimModalErrorMessage: '',
      hasSubclaimFlg: false,
      serviceOrderNumberSub: '',
      subtotal: 0.0,
      tax: 0.0,
      travel: 0.0,
      verifyServiceOrderNumberSub: '',
    });
  };

  handleBuyoutModalShow = async (buyoutPassword) => {
    this.setState({ subclaimModalErrorMessage: '' });
    const { buyoutKey, buyoutKey2, buyoutKey3, buyoutKey4, buyoutKey5, buyoutKey6 } =
      this.state;
    if (
      buyoutPassword === buyoutKey ||
      buyoutPassword === buyoutKey2 ||
      buyoutPassword === buyoutKey3 ||
      buyoutPassword === buyoutKey4 ||
      buyoutPassword === buyoutKey5 ||
      buyoutPassword === buyoutKey6
    ) {
      this.setState({
        showBuyoutModal: true,
        showServiceOrderModal: false,
      });
    } else {
      this.setState({ subclaimModalErrorMessage: 'Password Incorrect' });
    }
  };

  handleInputChange(event) {
    event.preventDefault();
    //this.resetValidation();

    const { target } = event;
    const { id, value } = target;

    if (id === 'serviceOrderNumberSub') {
      this.setState({ isCustomerDispatchSubclaim: null });
    }
    if (id === 'verifyServiceOrderNumberSub') {
      this.setState({ isCustomerDispatchSubclaim: null });
    }

    this.setState({ [id]: value });
  }

  handleResetViewModal = () => {
    this.setState({
      address: '',
      appointmentDetail: null,
      appointmentDt: '',
      appointmentTime: '',
      brand: '',
      buyoutPassword: '',
      city: '',
      claimStatus: '',
      claimType: '',
      contractNumber: '',
      deductibleDt: '',
      dispatchDt: '',
      errorMessage: '',
      failureCode: '',
      failureDescription: '',
      firstName: '',
      forcedBookingFlag: '',
      hasSubclaimFlg: false,
      installDt: '',
      lastName: '',
      modelName: '',
      phone: '',
      postalCode: '',
      purchaseDt: '',
      purchasePrice: '',
      serial: '',
      serviceOrderNumber: '',
      serviceOrderDetail: null,
      servicerName: '',
      servicerNumber: '',
      serviceOrderNumberSub: '',
      state: '',
      subclaimModalErrorMessage: '',
      verifyServiceOrderNumberSub: '',
    });
  };

  handleServiceOrderModalShow = async (serviceOrderNumber) => {
    let serviceOrderDetail = await this.getServiceOrderDetails(
      serviceOrderNumber
    );

    let appointmentDetail = await this.getAppointment(serviceOrderNumber);

    await this.getDeductiblePaymentDt();

    this.setState({
      appointmentDetail,
      serviceOrderDetail,
      showServiceOrderModal: true,
    });
  };

  handleServiceOrderModalClose = () => {
    this.setState({ showServiceOrderModal: false });
    this.handleResetViewModal();
  };

  handleSubmit = async () => {
    const {
      address,
      buyoutAmount,
      city,
      claimCashDisbursementReason,
      claimPayment,
      contractNumber,
      correctiveActionDescription,
      countryCode,
      dealerPricingGroupNumber,
      email,
      failureCode,
      firstName,
      foodLoss,
      freight,
      hasSubclaimFlg,
      labor,
      lastName,
      mileage,
      parts,
      paymentSource,
      phone,
      postalCode,
      repairDt,
      serviceOrderNumber,
      serviceOrderNumberSub,
      state,
      tax,
      travel,
    } = this.state;

    const { externalContractNumber } = this.props;

    let processErrorMessage = '';
    let response;

    if (this.validatePayout()) {
      try {
        this.setState({ isProcessing: true });
        if (claimCashDisbursementReason === 'reimbursement') {
          response = await processReimbursement(
            address,
            city,
            claimPayment,
            paymentSource,
            contractNumber,
            correctiveActionDescription,
            countryCode,
            dealerPricingGroupNumber,
            email,
            externalContractNumber,
            failureCode,
            firstName,
            foodLoss,
            freight,
            hasSubclaimFlg,
            labor,
            lastName,
            mileage,
            parts,
            phone,
            postalCode,
            repairDt,
            serviceOrderNumber,
            serviceOrderNumberSub,
            state,
            tax,
            travel
          );
        }

        if (claimCashDisbursementReason === 'buyout') {
          response = await processBuyout(
            address,
            buyoutAmount,
            city,
            correctiveActionDescription,
            paymentSource,
            contractNumber,
            countryCode,
            dealerPricingGroupNumber,
            email,
            externalContractNumber,
            failureCode,
            firstName,
            hasSubclaimFlg,
            lastName,
            phone,
            postalCode,
            repairDt,
            serviceOrderNumber,
            serviceOrderNumberSub,
            state
          );
        }

        if (response.status === 200) {
          if (response.data.status !== 200) {
            const { data } = response;
            processErrorMessage += `<br/><b>Error while attempting to process:</b><br/>${data.status} - ${data.message}`;
            let errorDetails = data?.data?.details;

            if (errorDetails?.length > 0) {
              processErrorMessage += '<ul>';
              errorDetails?.forEach((error) => {
                processErrorMessage += `<li>${error?.code} - ${error?.message}</li>`;
              });
              processErrorMessage += '</ul>';
            } else {
              errorDetails = data?.data;
              if (errorDetails.length > 0) {
                errorDetails?.forEach((error) => {
                  processErrorMessage += '<ul>';
                  Object.keys(error)?.forEach((key) => {
                    processErrorMessage += `<li>${key} - ${error[
                      key
                    ].toString()}</li>`;
                  });
                  processErrorMessage += '</ul>';
                });
              } else {
                errorDetails = data?.data?.data?.details;
                errorDetails?.forEach((error) => {
                  processErrorMessage += '<ul>';
                  Object.keys(error)?.forEach((key) => {
                    processErrorMessage += `<li>${key} - ${error[
                      key
                    ].toString()}</li>`;
                  });
                  processErrorMessage += '</ul>';
                });
              }
            }

            this.setState({ processErrorMessage, isProcessing: false });
            window.location.href = '#processErrorFound';
          } else {
            toast.success('Payout successful.', {
              duration: 5000,
            });
            this.setState({ isProcessing: false });
            setTimeout(() => {
              this.handleBuyoutModalClose();
            }, 5000);
          }
        } else {
          processErrorMessage += `<br/><b>Error attempting to process:</b><br/>${response.status} - ${response.message}`;
          this.setState({ processErrorMessage, isProcessing: false });
          window.location.href = '#processErrorFound';
        }
      } catch (error) {
        this.setState({
          processErrorMessage: `<br/><b>Error when attempting to process:</b><br/>${error}`,
          isProcessing: false,
        });
        window.location.href = '#processErrorFound';
      }
    }
  };

  handleValueChange(event) {
    const {
      deductibleInput,
      foodLoss,
      freight,
      isDeductibleRemoved,
      labor,
      mileage,
      parts,
      tax,
      travel,
    } = this.state;

    let subtotal = 0.0,
      total = 0.0;

    let isSubtractDeductible =
      event.target.id === 'isDeductibleRemoved'
        ? !isDeductibleRemoved
        : isDeductibleRemoved;

    subtotal = (
      parseFloat(isNaN(foodLoss) ? 0.0 : foodLoss) +
      parseFloat(isNaN(freight) ? 0.0 : freight) +
      parseFloat(isNaN(labor) ? 0.0 : labor) +
      parseFloat(isNaN(mileage) ? 0.0 : mileage) +
      parseFloat(isNaN(parts) ? 0.0 : parts) +
      parseFloat(isNaN(tax) ? 0.0 : tax) +
      parseFloat(isNaN(travel) ? 0.0 : travel)
    ).toFixed(2);


    total = isSubtractDeductible
      ? (
        parseFloat(isNaN(subtotal) ? 0 : subtotal) -
        parseFloat(isNaN(deductibleInput) ? 0 : deductibleInput)
      ).toFixed(2)
      : subtotal;

    this.setState({
      claimPayment: parseFloat(total) < 0 ? 0 : total,
      foodLoss: parseFloat(isNaN(foodLoss) ? 0 : foodLoss).toFixed(2),
      freight: parseFloat(isNaN(freight) ? 0 : freight).toFixed(2),
      isDeductibleRemoved: isSubtractDeductible,
      labor: parseFloat(isNaN(labor) ? 0 : labor).toFixed(2),
      mileage: parseFloat(isNaN(mileage) ? 0 : mileage).toFixed(2),
      parts: parseFloat(isNaN(parts) ? 0 : parts).toFixed(2),
      subtotal,
      tax: parseFloat(isNaN(tax) ? 0 : tax).toFixed(2),
      travel: parseFloat(isNaN(travel) ? 0 : travel).toFixed(2),
    });
  }

  renderServiceOrders = () => {
    const { serviceOrderList } = this.props;
    const sortedList = serviceOrderList.sort(
      (a, b) =>
        new Date(b.claimDateConsumerFiled) - new Date(a.claimDateConsumerFiled)
    );

    if (sortedList.length > 0) {
      return sortedList.map((serviceOrder) => {
        return (
          <tr
            key={serviceOrder.serviceOrderNumber}
            className={this.getVariant(serviceOrder)}
          >
            <td>{this.getButton(serviceOrder)}</td>
            <td>
              <Button
                onClick={() =>
                  this.handleServiceOrderModalShow(
                    serviceOrder.serviceOrderNumber
                  )
                }
                variant="outline-info"
                className="RoundedButton"
                size="md"
              >
                <FontAwesomeIcon icon={faFile} />
                <span className="ps-3">Details</span>
              </Button>
            </td>
            <td>{serviceOrder.serviceOrderNumber}</td>
            <td>{this.getSubcategory(serviceOrder)}</td>
            <td>
              {moment.utc(serviceOrder.claimDateConsumerFiled).format('MM/DD/YYYY')}
            </td>
            <td>{serviceOrder.productSequenceNumber}</td>
            <td>
              {serviceOrder.claimStatus === null
                ? 'Unknown'
                : serviceOrder.claimStatus}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="6">No service orders exist.</td>
        </tr>
      );
    }
  };

  resetValidation = () => {
    this.setState({
      isCorrectiveActionDescriptionValid: null,
      isCorrectiveActionDescriptionValidated: false,
      isEmailValid: null,
      isEmailValidated: false,
      isFirstNameValid: null,
      isFirstNameValidated: false,
      isLastNameValid: null,
      isLastNameValidated: false,
      isPhoneValid: null,
      isPhoneValidated: false,
      isRepairDtValid: null,
      isRepairDtValidated: false,
    });
  };

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  validatePayout = () => {
    const {
      claimCashDisbursementReason,
      buyoutAmount,
      buyoutAmountConfirm,
      claimPayment,
      correctiveActionDescription,
      email,
      firstName,
      hasSubclaimFlg,
      isCorrectiveActionDescriptionValid,
      isEmailValid,
      isFirstNameValid,
      isLastNameValid,
      isPhoneValid,
      isRepairDtValid,
      isCustomerDispatchSubclaim,
      lastName,
      phone,
      repairDt,
    } = this.state;

    this.setState({
      isCorrectiveActionDescriptionValid:
        correctiveActionDescription.trim().length > 0 ? true : false,
      isCorrectiveActionDescriptionValidated: true,
      isEmailValid: this.validateEmail(email),
      isEmailValidated: true,
      isFirstNameValid: firstName.trim().length > 0 ? true : false,
      isFirstNameValidated: true,
      isLastNameValid: lastName.trim().length > 0 ? true : false,
      isLastNameValidated: true,
      isPhoneValid: this.validatePhone(phone),
      isPhoneValidated: true,
      isRepairDtValid: moment(repairDt).isValid(),
      isRepairDtValidated: true,
    });

    const isReimbursementValid = claimPayment > 0;
    const isBuyoutValid =
      buyoutAmount > 0.0 && buyoutAmount === buyoutAmountConfirm;

    return claimCashDisbursementReason === 'reimbursement'
      ? isReimbursementValid &&
      isCorrectiveActionDescriptionValid &&
      isEmailValid &&
      isFirstNameValid &&
      isLastNameValid &&
      isPhoneValid &&
      isRepairDtValid &&
      (!hasSubclaimFlg || (hasSubclaimFlg && isCustomerDispatchSubclaim))
      : claimCashDisbursementReason === 'buyout'
        ? isBuyoutValid &&
        isCorrectiveActionDescriptionValid &&
        isEmailValid &&
        isFirstNameValid &&
        isLastNameValid &&
        isPhoneValid &&
        isRepairDtValid &&
        (!hasSubclaimFlg || (hasSubclaimFlg && isCustomerDispatchSubclaim))
        : false;
  };

  validatePhone(phone) {
    const cleanString = phone.replace(/\D/g, '');
    return cleanString?.length === 10;
  }

  render() {
    const {
      address,
      appointmentDt,
      appointmentTime,
      brand,
      buyoutAmount,
      buyoutAmountConfirm,
      buyoutPassword,
      city,
      claimCashDisbursementReason,
      claimPayment,
      claimStatus,
      claimType,
      contractNumber,
      correctiveActionDescription,
      dealerPricingGroupNumber,
      deductibleDt,
      deductibleInput,
      dispatchDt,
      email,
      failureCode,
      failureDescription,
      firstName,
      foodLoss,
      forcedBookingFlag,
      freight,
      hasSubclaimFlg,
      installDt,
      isCorrectiveActionDescriptionValid,
      isCorrectiveActionDescriptionValidated,
      isCustomerDispatch,
      isCustomerDispatchSubclaim,
      isDeductibleRemoved,
      isEmailValid,
      isEmailValidated,
      isFirstNameValid,
      isFirstNameValidated,
      isLastNameValid,
      isLastNameValidated,
      isPhoneValid,
      isPhoneValidated,
      isProcessing,
      isRepairDtValid,
      isRepairDtValidated,
      labor,
      lastName,
      mileage,
      modalErrorMessage,
      modelName,
      parts,
      paymentSource,
      phone,
      postalCode,
      processErrorMessage,
      purchaseDt,
      purchasePrice,
      repairDt,
      serial,
      servicerName,
      servicerNumber,
      serviceOrderNumber,
      serviceOrderNumberSub,
      showBuyoutModal,
      showServiceOrderModal,
      state,
      subclaimModalErrorMessage,
      subtotal,
      tax,
      travel,
      verifyEmail,
      verifyPhone,
      verifyServiceOrderNumberSub,
    } = this.state;

    const { deductible, deductibleTerm } = this.props;

    let apptDt = forcedBookingFlag === 'YES' ? 'Forced' : appointmentDt;

    return (
      <Fragment>
        <Modal keyboard={false} show={showServiceOrderModal} size="lg">
          <Modal.Header>
            <Modal.Title>Service Order Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <Table>
                    <thead>
                      <tr>
                        <th>Contract</th>
                        <th>Claim</th>
                        <th>Status</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{contractNumber}</td>
                        <td>{serviceOrderNumber}</td>
                        <td>{claimStatus}</td>
                        <td>{claimType}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table className="mt-5">
                    <thead>
                      <tr>
                        <th>Servicer ID</th>
                        <th>Servicer</th>
                        <th>Appointment Date</th>
                        <th>Appointment Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{servicerNumber}</td>
                        <td>{servicerName}</td>
                        <td>{apptDt}</td>
                        <td>{appointmentTime}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table className="mt-5">
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th colSpan="2">Contact Information</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{firstName}</td>
                        <td>{lastName}</td>
                        <td>{address}</td>
                        <td>{city}</td>
                      </tr>
                      <tr>
                        <td colSpan="2">&nbsp;</td>
                        <td>{state}</td>
                        <td>{postalCode}</td>
                      </tr>
                      <tr>
                        <td colSpan="2">{email}</td>
                        <td colSpan="2">
                          {phone?.length > 0 ? this.formatPhone(phone) : phone}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table className="mt-5">
                    <thead>
                      <tr>
                        <th>Brand</th>
                        <th>Model</th>
                        <th>Serial Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{brand}</td>
                        <td>{modelName}</td>
                        <td>{serial}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table className="mt-1">
                    <thead>
                      <tr>
                        <th>Purchase Date</th>
                        <th>Install Date</th>
                        <th>Purchase Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {new Date(purchaseDt).toLocaleDateString('en-US')}
                        </td>
                        <td>
                          {new Date(installDt).toLocaleDateString('en-US')}
                        </td>
                        <td>${purchasePrice}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table className="mt-5">
                    <thead>
                      <tr>
                        <th>Failure Code</th>
                        <th>Failure Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{failureCode}</td>
                        <td>{failureDescription}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table className="mt-5">
                    <thead>
                      <tr>
                        <th>Deductible</th>
                        <th>Collected</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          ${deductible}/{deductibleTerm}
                        </td>
                        <td>{deductibleDt}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              {modalErrorMessage?.length > 0 ? (
                <Row className="mt-3 ps-3 pe-3">
                  <Col>
                    <Alert id="modalErrorFound" variant="danger">
                      {parse(modalErrorMessage)}
                    </Alert>
                  </Col>
                </Row>
              ) : null}
              {!isCustomerDispatch ? (
                <Alert variant="info">
                  To process a buyout or reimbursement, the service order must
                  be dispatched to customer first.
                </Alert>
              ) : null}
              <Row className="mt-3 ps-3 pe-3">
                <Col>
                  Apply to subclaim?{' '}
                  <Form.Check
                    type="checkbox"
                    onChange={() => {
                      this.setState({
                        hasSubclaimFlg: !hasSubclaimFlg,
                      });
                    }}
                    checked={hasSubclaimFlg}
                  />
                </Col>
              </Row>
              {hasSubclaimFlg ? (
                <Row>
                  <Col>
                    <tr>
                      <td className="text-end">Subclaim:</td>
                      <td className="ps-3">
                        <Form.Group controlId="serviceOrderNumberSub">
                          <Form.Control
                            autoComplete="off"
                            className={
                              serviceOrderNumberSub?.length > 0 &&
                                verifyServiceOrderNumberSub?.length > 0 &&
                                serviceOrderNumberSub ===
                                verifyServiceOrderNumberSub
                                ? 'is-valid'
                                : 'is-invalid'
                            }
                            disabled={isProcessing}
                            onChange={(event) => {
                              this.handleInputChange(event);
                            }}
                            placeholder="subclaim"
                            size="md"
                            type="text"
                            value={serviceOrderNumberSub}
                          />
                          <Form.Control.Feedback type="invalid">
                            {serviceOrderNumberSub?.length === 0
                              ? 'Please provide a subclaim.'
                              : 'Subclaims do not match.'}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </td>
                      <td className="ps-3 text-end">Verify subclaim:</td>
                      <td className="ps-3">
                        <Form.Group controlId="verifyServiceOrderNumberSub">
                          <Form.Control
                            autoComplete="off"
                            className={
                              verifyServiceOrderNumberSub?.length > 0 &&
                                serviceOrderNumberSub ===
                                verifyServiceOrderNumberSub
                                ? 'is-valid'
                                : 'is-invalid'
                            }
                            disabled={isProcessing}
                            onChange={(event) => {
                              this.handleInputChange(event);
                            }}
                            placeholder="subclaim"
                            size="md"
                            type="text"
                            value={verifyServiceOrderNumberSub}
                          />
                          <Form.Control.Feedback type="invalid">
                            {verifyServiceOrderNumberSub?.length === 0
                              ? 'Please provide a valid subclaim.'
                              : 'Subclaims do not match.'}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </td>
                      <td className="ps-3">
                        <Button
                          size="md"
                          onClick={() => {
                            this.getServiceOrderSubclaimDetails();
                          }}
                        >
                          Check dispatch status
                        </Button>
                      </td>
                      <td className="ps-3">
                        {isCustomerDispatchSubclaim === true && (
                          <span className="text-success">
                            <FontAwesomeIcon
                              className="me-2"
                              icon={faCheck}
                              size="lg"
                            />
                            Claim is dispatched to customer.
                          </span>
                        )}
                        {isCustomerDispatchSubclaim === false && (
                          <span className="text-danger">
                            <FontAwesomeIcon className="me-2" icon={faBan} />
                            Claim is not dispatched to customer.
                          </span>
                        )}
                      </td>
                    </tr>
                  </Col>
                </Row>
              ) : null}
              {isCustomerDispatch || isCustomerDispatchSubclaim ? (
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="buyoutPassword">
                      <Form.Label>Buyout Password</Form.Label>
                      <Form.Control
                        type="password"
                        onChange={(event) => {
                          this.handleInputChange(event);
                        }}
                        placeholder="Password"
                        value={buyoutPassword}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ) : null}
              {subclaimModalErrorMessage?.length > 0 ? (
                <Row className="mt-3 ps-3 pe-3">
                  <Col>
                    <Alert id="subclaimModalErrorFound" variant="danger">
                      {parse(subclaimModalErrorMessage)}
                    </Alert>
                  </Col>
                </Row>
              ) : null}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            {isCustomerDispatch || isCustomerDispatchSubclaim ? (
              <Button
                variant="primary"
                onClick={() => this.handleBuyoutModalShow(buyoutPassword)}
              >
                Buyout/Reimbursement
              </Button>
            ) : null}
            <Button
              variant="secondary"
              onClick={() => this.handleServiceOrderModalClose()}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal keyboard={false} show={showBuyoutModal} size="xl">
          <Modal.Header>
            <Modal.Title>Buyout and Reimbursement Processing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <h2 className="mb-3">
                    Dealer Pricing Group: {dealerPricingGroupNumber}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2>Details</h2>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Table>
                    <thead>
                      <tr>
                        <th>Contract</th>
                        <th>Claim</th>
                        <th>Dispatch Date</th>
                        <th>Dispatch To</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{contractNumber}</td>
                        <td>{serviceOrderNumber}</td>
                        <td>{dispatchDt}</td>
                        <td>{isCustomerDispatch ? 'Customer' : null}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2>Recipient Information</h2>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Table>
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th colSpan="2">Contact Information</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Form.Group controlId="firstName">
                            <Form.Control
                              autoComplete="off"
                              className={
                                !isFirstNameValidated
                                  ? null
                                  : isFirstNameValid
                                    ? 'is-valid'
                                    : 'is-invalid'
                              }
                              disabled={isProcessing}
                              onChange={(event) => {
                                this.handleInputChange(event);
                              }}
                              placeholder="First Name"
                              size="md"
                              tabIndex="1"
                              type="text"
                              value={firstName}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a first name.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group controlId="lastName">
                            <Form.Control
                              autoComplete="off"
                              className={
                                !isLastNameValidated
                                  ? null
                                  : isLastNameValid
                                    ? 'is-valid'
                                    : 'is-invalid'
                              }
                              disabled={isProcessing}
                              onChange={(event) => {
                                this.handleInputChange(event);
                              }}
                              placeholder="Last Name"
                              size="md"
                              tabIndex="2"
                              type="text"
                              value={lastName}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a last name.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        <td>{address}</td>
                        <td>{city}</td>
                      </tr>
                      <tr>
                        <td>
                          <Form.Group controlId="email">
                            <Form.Control
                              autoComplete="off"
                              className={
                                !isEmailValidated
                                  ? null
                                  : isEmailValid
                                    ? 'is-valid'
                                    : 'is-invalid'
                              }
                              disabled={isProcessing}
                              onChange={(event) => {
                                this.handleInputChange(event);
                              }}
                              placeholder="Email"
                              size="md"
                              tabIndex="3"
                              type="email"
                              value={email}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid email.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group controlId="phone">
                            <Form.Control
                              autoComplete="off"
                              className={
                                !isPhoneValidated
                                  ? null
                                  : isPhoneValid
                                    ? 'is-valid'
                                    : 'is-invalid'
                              }
                              disabled={isProcessing}
                              onBlur={(event) => {
                                this.handleValueChange(event);
                              }}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                              placeholder="Phone"
                              size="md"
                              tabIndex="4"
                              type="text"
                              value={
                                phone?.length > 0
                                  ? this.formatPhone(phone)
                                  : phone
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid phone number.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        <td>{state}</td>
                        <td>{postalCode}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2>Expense Information</h2>
                </Col>
              </Row>
              <Row>
                <Form.Label column xs={2}>
                  Type:
                </Form.Label>
                <Col xs={4}>
                  <Form.Control
                    as="select"
                    disabled={isProcessing}
                    onChange={(event) => {
                      this.setState({
                        claimCashDisbursementReason: event.target.value,
                      });
                    }}
                    size="md"
                    tabIndex="5"
                    value={claimCashDisbursementReason}
                  >
                    <option key="reimbursement" value="reimbursement">
                      Reimbursement
                    </option>
                    <option key="buyout" value="buyout">
                      Buyout
                    </option>
                  </Form.Control>
                </Col>
                <Form.Label column xs={2}>
                  Repair Date:
                </Form.Label>
                <Col xs={4}>
                  <DatePicker
                    as={Form.Control}
                    className={
                      !isRepairDtValidated
                        ? null
                        : isRepairDtValid
                          ? 'is-valid'
                          : 'is-invalid'
                    }
                    disabled={isProcessing}
                    disabledKeyboardNavigation
                    maxDate={new Date()}
                    onSelect={(date) =>
                      this.setState({
                        repairDt: moment(date).format('MM/DD/YYYY'),
                      })
                    }
                    selected={new Date()}
                    tabIndex="6"
                    value={repairDt}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please choose a valid date.
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row>
                <Form.Label column xs={2}>
                  Failure Code:
                </Form.Label>
                <Col xs={4}>
                  <Form.Control defaultValue={failureCode} plaintext readOnly />
                </Col>
                <Form.Label column xs={2}>
                  Failure Description:
                </Form.Label>
                <Col xs={4}>
                  <Form.Control
                    defaultValue={failureDescription}
                    plaintext
                    readOnly
                  />
                </Col>
              </Row>
              <Form.Group as={Row} controlId="correctiveActionDescription">
                <Form.Label column xs={2}>
                  Corrective Action:
                </Form.Label>
                <Col xs={4}>
                  <Form.Control
                    as="textarea"
                    className={
                      !isCorrectiveActionDescriptionValidated
                        ? null
                        : isCorrectiveActionDescriptionValid
                          ? 'is-valid'
                          : 'is-invalid'
                    }
                    disabled={isProcessing}
                    onChange={(event) => {
                      this.handleInputChange(event);
                    }}
                    placeholder="notes"
                    size="md"
                    tabIndex="7"
                    value={correctiveActionDescription}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide detail.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {claimCashDisbursementReason === 'reimbursement' ? (
                <Row className="mt-2">
                  <Table>
                    <thead>
                      <tr>
                        <th>Parts</th>
                        <th>Labor</th>
                        <th>Food Loss</th>
                        <th>Freight</th>
                        <th>Mileage</th>
                        <th>Travel</th>
                        <th>Tax</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Form.Group controlId="parts">
                            <Form.Control
                              autoComplete="off"
                              disabled={isProcessing}
                              onBlur={(event) => {
                                this.handleValueChange(event);
                              }}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                              size="md"
                              tabIndex="8"
                              type="text"
                              value={parts}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group controlId="labor">
                            <Form.Control
                              autoComplete="off"
                              disabled={isProcessing}
                              onBlur={(event) => {
                                this.handleValueChange(event);
                              }}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                              size="md"
                              tabIndex="9"
                              type="text"
                              value={labor}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group controlId="foodLoss">
                            <Form.Control
                              autoComplete="off"
                              disabled={isProcessing}
                              onBlur={(event) => {
                                this.handleValueChange(event);
                              }}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                              size="md"
                              tabIndex="10"
                              type="text"
                              value={foodLoss}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group controlId="freight">
                            <Form.Control
                              autoComplete="off"
                              disabled={isProcessing}
                              onBlur={(event) => {
                                this.handleValueChange(event);
                              }}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                              size="md"
                              tabIndex="11"
                              type="text"
                              value={freight}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group controlId="mileage">
                            <Form.Control
                              autoComplete="off"
                              disabled={isProcessing}
                              onBlur={(event) => {
                                this.handleValueChange(event);
                              }}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                              size="md"
                              tabIndex="12"
                              type="text"
                              value={mileage}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group controlId="travel">
                            <Form.Control
                              autoComplete="off"
                              disabled={isProcessing}
                              onBlur={(event) => {
                                this.handleValueChange(event);
                              }}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                              size="md"
                              tabIndex="13"
                              type="text"
                              value={travel}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group controlId="tax">
                            <Form.Control
                              autoComplete="off"
                              disabled={isProcessing}
                              onBlur={(event) => {
                                this.handleValueChange(event);
                              }}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                              size="md"
                              tabIndex="14"
                              type="text"
                              value={tax}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group controlId="subtotal">
                            <Form.Control
                              readOnly
                              size="md"
                              type="text"
                              value={subtotal}
                            />
                          </Form.Group>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="5"></td>
                        <td>
                          <Form.Check
                            checked={isDeductibleRemoved}
                            disabled={isProcessing}
                            id="isDeductibleRemoved"
                            label="Deduct Deductible"
                            onChange={(event) => {
                              this.handleValueChange(event);
                            }}
                            tabIndex="15"
                            type="checkbox"
                          />
                        </td>
                        <td>Deductible:</td>
                        <td>
                          <Form.Group controlId="deductibleInput">
                            <Form.Control
                              disabled={isProcessing}
                              onBlur={(event) => {
                                this.handleValueChange(event);
                              }}
                              size="md"
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                              tabIndex="16"
                              type="text"
                              value={deductibleInput}
                            />
                          </Form.Group>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="6"></td>
                        <td>
                          <strong>Payment</strong>:
                        </td>
                        <td>
                          {claimPayment}
                          {claimPayment < 0.01 && (
                            <span className="text-danger">
                              <br />
                              No payout needed.
                            </span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
              ) : null}
              {claimCashDisbursementReason === 'buyout' ? (
                <Row className="mt-2">
                  <Form.Label column xs={2}>
                    Buyout Amount:
                  </Form.Label>
                  <Col xs={4}>
                    <Form.Group controlId="buyoutAmount">
                      <Form.Control
                        autoComplete="off"
                        className={
                          buyoutAmount?.length > 0 &&
                            buyoutAmountConfirm?.length > 0 &&
                            buyoutAmount === buyoutAmountConfirm
                            ? 'is-valid'
                            : 'is-invalid'
                        }
                        disabled={isProcessing}
                        onChange={(event) => {
                          this.handleInputChange(event);
                        }}
                        placeholder="0.00"
                        size="md"
                        type="text"
                        value={buyoutAmount}
                      />
                      <Form.Control.Feedback type="invalid">
                        Amounts must be greater than zero and match.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Form.Label column xs={2}>
                    Confirm Amount:
                  </Form.Label>
                  <Col xs={4}>
                    <Form.Group controlId="buyoutAmountConfirm">
                      <Form.Control
                        autoComplete="off"
                        className={
                          buyoutAmount?.length > 0 &&
                            buyoutAmountConfirm?.length > 0 &&
                            buyoutAmount === buyoutAmountConfirm
                            ? 'is-valid'
                            : 'is-invalid'
                        }
                        disabled={isProcessing}
                        onChange={(event) => {
                          this.handleInputChange(event);
                        }}
                        placeholder="0.00"
                        size="md"
                        type="text"
                        value={buyoutAmountConfirm}
                      />
                      <Form.Control.Feedback type="invalid">
                        Amounts must be greater than zero and match.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col>
                  <h2>Payment Source</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Check
                    defaultChecked="checked"
                    disabled={isProcessing}
                    id="PayPal"
                    inline
                    label="Paypal"
                    name="paymentSource"
                    onChange={() =>
                      this.setState({
                        paymentSource: 'PayPal',
                        verifyPhone: '',
                      })
                    }
                    type="radio"
                  />
                  <Form.Check
                    disabled={isProcessing}
                    id="Venmo"
                    inline
                    label="Venmo"
                    name="paymentSource"
                    onChange={() =>
                      this.setState({
                        paymentSource: 'Venmo',
                        verifyEmail: '',
                      })
                    }
                    type="radio"
                  />
                </Col>
              </Row>
              <Row>
                <Table>
                  <tbody>
                    {paymentSource === 'PayPal' ? (
                      <tr>
                        <td className="text-end">Paypal Email:</td>
                        <td>
                          <Form.Group controlId="email">
                            <Form.Control
                              autoComplete="off"
                              className={
                                email?.length > 0 &&
                                  this.validateEmail(email) &&
                                  verifyEmail?.length > 0 &&
                                  email === verifyEmail
                                  ? 'is-valid'
                                  : 'is-invalid'
                              }
                              disabled={isProcessing}
                              onChange={(event) => {
                                this.handleInputChange(event);
                              }}
                              placeholder="Email"
                              size="md"
                              type="email"
                              value={email}
                            />
                            <Form.Control.Feedback type="invalid">
                              {email?.length === 0 || !this.validateEmail(email)
                                ? 'Please provide a valid email.'
                                : 'Emails do not match.'}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        <td className="text-end">Verify Email:</td>
                        <td>
                          <Form.Group controlId="verifyEmail">
                            <Form.Control
                              autoComplete="off"
                              className={
                                verifyEmail?.length > 0 &&
                                  this.validateEmail(verifyEmail) &&
                                  email === verifyEmail
                                  ? 'is-valid'
                                  : 'is-invalid'
                              }
                              disabled={isProcessing}
                              onChange={(event) => {
                                this.handleInputChange(event);
                              }}
                              placeholder="Email"
                              size="md"
                              type="email"
                              value={verifyEmail}
                            />
                            <Form.Control.Feedback type="invalid">
                              {verifyEmail?.length === 0 ||
                                !this.validateEmail(verifyEmail)
                                ? 'Please provide a valid email.'
                                : 'Emails do not match.'}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </td>
                      </tr>
                    ) : null}
                    {paymentSource === 'Venmo' ? (
                      <tr>
                        <td className="text-end">Venmo Phone:</td>
                        <td>
                          <Form.Group controlId="phone">
                            <Form.Control
                              autoComplete="off"
                              className={
                                phone?.length > 0 &&
                                  verifyPhone?.length > 0 &&
                                  phone === verifyPhone
                                  ? 'is-valid'
                                  : 'is-invalid'
                              }
                              disabled={isProcessing}
                              maxLength={14}
                              onChange={(event) => {
                                this.handleInputChange(event);
                              }}
                              placeholder="(123) 456-7890"
                              size="md"
                              type="text"
                              value={
                                phone?.length > 0
                                  ? this.formatPhone(phone)
                                  : phone
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {phone?.length === 0
                                ? 'Please enter a phone number.'
                                : 'Phone numbers do not match.'}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        <td className="text-end">Verify Phone:</td>
                        <td>
                          <Form.Group controlId="verifyPhone">
                            <Form.Control
                              autoComplete="off"
                              className={
                                verifyPhone?.length > 0 &&
                                  phone?.length > 0 &&
                                  phone === verifyPhone
                                  ? 'is-valid'
                                  : 'is-invalid'
                              }
                              disabled={isProcessing}
                              maxLength={14}
                              onChange={(event) => {
                                this.handleInputChange(event);
                              }}
                              placeholder="(123) 456-7890"
                              size="md"
                              type="text"
                              value={
                                verifyPhone?.length > 0
                                  ? this.formatPhone(verifyPhone)
                                  : verifyPhone
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {verifyPhone?.length === 0
                                ? 'Please enter a phone number.'
                                : 'Phone numbers do not match.'}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
              </Row>
              {processErrorMessage.length > 0 ? (
                <Row className="mt-3 ps-3 pe-3">
                  <Col>
                    <Alert id="processErrorFound" variant="danger">
                      {parse(processErrorMessage)}
                    </Alert>
                  </Col>
                </Row>
              ) : null}
              <Toaster
                position="top-left"
                toastOptions={{
                  success: {
                    style: {
                      background: 'green',
                      color: 'white',
                    },
                  },
                  error: {
                    style: {
                      background: 'red',
                      color: 'white',
                    },
                  },
                }}
              />
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isProcessing}
              onClick={() => this.handleSubmit()}
              variant="primary"
            >
              {isProcessing ? <Spinner animation="border"></Spinner> : 'Submit'}
            </Button>
            <Button
              disabled={isProcessing}
              onClick={() => this.handleBuyoutModalClose()}
              variant="secondary"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col>
            <h4>Service Orders</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table>
              <thead>
                <tr>
                  <th colSpan={2}>&nbsp;</th>
                  <th>Code</th>
                  <th>Description</th>
                  <th>Filed</th>
                  <th>SEQ #</th>
                  <th>Job Status</th>
                </tr>
              </thead>
              <tbody className="custom-scrollbar">{this.renderServiceOrders()}</tbody>
            </Table>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default ServiceOrderList;
