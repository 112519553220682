import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, MenuItem, Select } from '@mui/material';
import Delivery from "../../img/delivery-box (1) 1.svg"
// import { StyleSelect } from '../../components/StyledComponent/StyledComponent';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function RescheduleDialog({ open, rescheduleReasonList, handleClose, value, handleChange, openRescheduleAppointmentDialog }) {

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}

                PaperProps={{
                    style: {
                        borderRadius: '20px',
                        width: "29rem",
                        height: "25rem"
                    },
                }}
            >
                <DialogTitle style={{ textAlign: "center" }} sx={{ m: 2, p: 2, mb: 0 }} id="customized-dialog-title">
                    <img src={Delivery} alt='user-pic' height="45px" width="45px" style={{ background: "rgba(235, 248, 255, 1)", padding: "10px", borderRadius: "50%" }} />
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 15,
                        top: 15,
                        height: "25px",
                        width: "25px",
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ textAlign: "center" }} >
                    <Grid >
                        <span style={{ fontSize: "large" }}>
                            <span style={{ fontWeight: "600" }}>Do you want to Reschedule the Appointment ?</span>
                        </span>
                    </Grid>
                    <br />
                    <Grid>
                        <span style={{ fontWeight: "400", fontSize: 'medium' }}>Please provide the reason to reschedule:</span>
                        <br />
                        <br />
                        <Select
                            sx={{ width: "80%" }}
                            value={value}
                            onChange={(event) => {
                                // console.log(event.target.value, "value")
                                handleChange(event.target.value)
                            }}
                        >
                            {
                                rescheduleReasonList?.map((item) => (
                                    <MenuItem key={item.reasonCodeMasterId} value={item.reasonName}>
                                        {item.reasonName}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </Grid>
                    <br />
                    <Grid item xs={12} style={{ justifyContent: "center", marginTop: "1.8rem" }}>

                        <Button variant="outlined" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="contained" disabled={!value ? true : false} style={{ marginLeft: "20px" }} onClick={openRescheduleAppointmentDialog}>
                            Yes
                        </Button>
                    </Grid>

                </DialogContent>

            </BootstrapDialog>
        </>
    );
}