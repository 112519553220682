import React, { Component, Fragment } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { FormControl, MenuItem, Select } from "@mui/material";

class LossCodeList extends Component {
  componentDidMount() {
    const { lossCodeList, handleLossCodeSelect, selectedLossCode } = this.props;

    // Only trigger handleLossCodeSelect if no selectedLossCode is set
    if (lossCodeList.length > 0 && !selectedLossCode) {
      handleLossCodeSelect(lossCodeList[0]);
    }
  }

  componentDidUpdate(prevProps) {
    const { lossCodeList, handleLossCodeSelect, selectedLossCode } = this.props;

    // Avoid redundant API calls by ensuring we only trigger handleLossCodeSelect when necessary
    if (
      prevProps.lossCodeList !== lossCodeList &&
      lossCodeList.length > 0 &&
      !selectedLossCode
    ) {
      handleLossCodeSelect(lossCodeList[0]);
    }
  }

  renderLossCodes = () => {
    const { lossCodeList } = this.props;

    return lossCodeList.map((code) => (
      <MenuItem key={code.code} value={code.code}>
        {code.description.substring(0, 50)}
      </MenuItem>
    ));
  };

  render() {
    const { lossCodeList, selectedLossCode, handleLossCodeSelect, loading } =
      this.props;

    return (
      <Fragment>
        {loading && (
          <Row>
            <Col>
              <Spinner animation="border"></Spinner>
              <br />
              <span className="spinner-text">Re-loading loss code list...</span>
            </Col>
          </Row>
        )}
        {!loading && (
          <>
            <h2>Product</h2>
            <FormControl sx={{ width: "50%" }}>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={
                  selectedLossCode?.code ||
                  (lossCodeList.length > 0 ? lossCodeList[0].code : "")
                }
                onChange={(e) => {
                  handleLossCodeSelect(
                    lossCodeList.find((code) => code.code === e.target.value)
                  );
                }}
                MenuProps={{
                  PaperProps: {
                    className: "custom-scrollbar",
                  },
                }}
              >
                {lossCodeList.length > 0 ? (
                  this.renderLossCodes()
                ) : (
                  <MenuItem disabled>No loss codes available.</MenuItem>
                )}
              </Select>
            </FormControl>
          </>
        )}
      </Fragment>
    );
  }
}

export default LossCodeList;
